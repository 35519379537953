/* eslint-disable max-len */
import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'

import Helpers from '../Helpers'

import '../styles/FilterInput.css'
import {Icon, Switch, SearchBar, Select, Input} from '@lazarusai/forms-ui-components'

import {storePayload} from '../actions/storePayload'
import {toggleVisualization} from '../actions/toggleVisualization'

import ColorSelect from './ColorSelect'

import cubeIcon from '../images/cube.svg'
import sphereIcon from '../images/sphere.svg'
import triangleIcon from '../images/triangle.svg'
import gradientIcon from '../images/gradient.svg'
import gradientNodeIcon from '../images/gradient-node.svg'

function FilterInput(props) {
  const [isFilterInputOpen, setIsFilterInputOpen] = useState(false)
  const [filterInputType, setFilterInputType] = useState(null)
  const [searchString, setSearchString] = useState('')

  useEffect(() => {
    if (props.metadataValues?.[props.metadataKey]) {
      const exampleVal = props.metadataValues?.[props.metadataKey].filter((val) => val !== null)[0]
      const type = Helpers.getInputType(exampleVal)
      setFilterInputType(type)
    }
  }, [props.metadataKey, props.metadataValues])

  function isRangeInvalid() {
    return (parseFloat(props.activeFilters[props.metadataKey]?.range?.from || props.metadataValues[props.metadataKey][0]) > parseFloat(props.activeFilters[props.metadataKey]?.range?.to || props.metadataValues[props.metadataKey][props.metadataValues[props.metadataKey].length - 1]))
  }

  function searchCompareFunction(aKey, bKey) {
    if (!searchString?.length) {
      return 0
    }
    const aIndex = aKey.toLowerCase().indexOf(searchString.toLowerCase())
    const bIndex = bKey.toLowerCase().indexOf(searchString.toLowerCase())
    const aVal = aIndex > -1 ? aIndex: 100
    const bVal = bIndex > -1 ? bIndex: 100
    return aVal === bVal ? 0 : aVal < bVal ? -1: 1
  }

  function getBody() {
    if (filterInputType === 'string') {
      return (
        <>
          <SearchBar
            isCollapsed={false}
            isCollapsible={false}
            theme={props.theme}
            placeHolder={'Search Filters'}
            searchValue={searchString}
            onChange={(e) => {
              setSearchString(e.target.value)
            }}
            inputId={'filter-input-search-input'}
          />
          <div className='string-filter-selects'>
            <div>
              <span
                className='cursor-pointer'
                onClick={() => {
                  const newFilter = JSON.parse(JSON.stringify(props.activeFilters))
                  newFilter[props.metadataKey] = props.metadataValues[props.metadataKey]
                  props.storePayload({
                    activeFilters: newFilter,
                  })
                }}
              >Select All</span>
              <span>&nbsp;&nbsp;&#x2022;&nbsp;&nbsp;</span>
              <span
                className='cursor-pointer'
                onClick={() => {
                  const newFilter = JSON.parse(JSON.stringify(props.activeFilters))
                  newFilter[props.metadataKey] = []
                  props.storePayload({
                    activeFilters: newFilter,
                  })
                }}
              >Clear</span>
            </div>
            {props.visualizations?.[props.metadataKey]?.isActive && <Icon
              icon='color-palette-outline'
            />}
          </div>
          <div className='string-values filter-scroll'>
            {props.metadataValues?.[props.metadataKey].sort(searchCompareFunction).map((value, index) => {
              return (
                <div
                  className='string-value-row'
                  key={`${value}-${index}`}
                >
                  <Switch
                    checked={props.activeFilters[props.metadataKey].includes(value)}
                    type='checkbox'
                    theme={props.theme}
                    onChange={(e) => {
                      if (e.target.checked) {
                        const newFilter = JSON.parse(JSON.stringify(props.activeFilters))
                        newFilter[props.metadataKey].push(value)
                        props.storePayload({
                          activeFilters: newFilter,
                        })
                      } else {
                        const newFilter = JSON.parse(JSON.stringify(props.activeFilters))
                        newFilter[props.metadataKey] = newFilter[props.metadataKey].filter((val) => val !== value)
                        props.storePayload({
                          activeFilters: newFilter,
                        })
                      }
                    }}
                  />
                  <div className='string-value-text'>
                    {value ? value : <i>null</i>}
                  </div>
                  {props.visualizations?.[props.metadataKey]?.isActive && <ColorSelect
                    key={'color-'+value}
                    metadataKey={props.metadataKey}
                    value={value}
                    selectedColor={props.visualizations[props.metadataKey]?.['color']?.[value] || null}
                    onColorClick={(color) => {
                      const newVis = JSON.parse(JSON.stringify(props.visualizations))
                      if (!newVis[props.metadataKey].color) {
                        newVis[props.metadataKey].color = {}
                      }
                      newVis[props.metadataKey]['color'][value] = color
                      props.storePayload({
                        visualizations: newVis,
                      })
                    }}
                  />}
                </div>
              )
            })}
          </div>
        </>
      )
    } else if (filterInputType === 'boolean') {
      return (
        <>
          <div className='boolean-value-row'>
            <div>Show Value(s)</div>
            <Select
              theme={props.theme}
              options={['All', 'FALSE', 'TRUE']}
              onClickOption={(option) => {
                if (option === 'All') {
                  const newFilter = JSON.parse(JSON.stringify(props.activeFilters))
                  newFilter[props.metadataKey] = props.metadataValues?.[props.metadataKey]
                  props.storePayload({
                    activeFilters: newFilter,
                  })
                } else {
                  const newFilter = JSON.parse(JSON.stringify(props.activeFilters))
                  newFilter[props.metadataKey] = option === 'FALSE' ?
                    props.metadataValues?.[props.metadataKey].filter((value) => (value && value.toLowerCase() === 'false')) :
                    props.metadataValues?.[props.metadataKey].filter((value) => (value && value.toLowerCase() === 'true'))
                  props.storePayload({
                    activeFilters: newFilter,
                  })
                }
              }}
              value={
                !(props.activeFilters[props.metadataKey].length === 1) ?
                  'All':
                  props.activeFilters[props.metadataKey].map((val) => val.toLowerCase()).includes('true') ?
                    'TRUE':
                    'FALSE'
              }
              label={null}
            />
          </div>
          {props.visualizations?.[props.metadataKey]?.isActive && <>
            <div className='boolean-value-visualization'>
              <div>TRUE</div>
              <div><img src={cubeIcon} /></div>
            </div>
            <div className='boolean-value-visualization'>
              <div>False</div>
              <div><img src={triangleIcon} /></div>
            </div>
            <div className='boolean-value-visualization'>
              <div><i>Null</i></div>
              <div><img src={sphereIcon} /></div>
            </div>
          </>}
        </>
      )
    } else if (filterInputType === 'number') {
      return (
        <>
          <div
            className='number-value-row'
          >
            <div
              className='number-value-input'
            >
              <Input
                name={`${props.metadataKey}-num-min`}
                id={`${props.metadataKey}-num-min`}
                type={1}
                inputType='number'
                theme={props.theme}
                label='Minimum'
                placeholder={'Enter Value'}
                value={`${props.activeFilters[props.metadataKey]?.range?.from !== undefined ? props.activeFilters[props.metadataKey]?.range?.from: ''}`}
                onChange={(e) => {
                  const newFilter = JSON.parse(JSON.stringify(props.activeFilters))
                  const val = e.target.value < props.metadataValues[props.metadataKey][0] ?
                    props.metadataValues[props.metadataKey][0] :
                    e.target.value > props.metadataValues[props.metadataKey][props.metadataValues[props.metadataKey].length - 1] ?
                      props.metadataValues[props.metadataKey][props.metadataValues[props.metadataKey].length - 1] :
                      e.target.value
                  newFilter[props.metadataKey]['range']['from'] = parseFloat(val)
                  props.storePayload({
                    activeFilters: newFilter,
                  })
                }}
                className={`number-input-component ${isRangeInvalid() ? 'number-value-input-warning': ''}`}
                info={isRangeInvalid() ? 'Invalid Value' :`Minimum: ${props.metadataValues[props.metadataKey][0]}`}
                min={parseFloat(props.metadataValues[props.metadataKey][0])}
                max={parseFloat(props.metadataValues[props.metadataKey][props.metadataValues[props.metadataKey].length - 1])}
              />
            </div>
            <div className='number-value-dash'>
              <Icon
                icon='minus-outline'
              />
            </div>
            <div
              className='number-value-input'
            >
              <Input
                name={`${props.metadataKey}-num-max`}
                id={`${props.metadataKey}-num-max`}
                type={1}
                inputType='number'
                theme={props.theme}
                label='Maximum'
                placeholder={'Enter Value'}
                value={`${props.activeFilters[props.metadataKey]?.range?.to !== undefined ? props.activeFilters[props.metadataKey]?.range?.to: ''}`}
                onChange={(e) => {
                  const newFilter = JSON.parse(JSON.stringify(props.activeFilters))
                  const val = e.target.value < props.metadataValues[props.metadataKey][0] ?
                    props.metadataValues[props.metadataKey][0] :
                    e.target.value > props.metadataValues[props.metadataKey][props.metadataValues[props.metadataKey].length - 1] ?
                      props.metadataValues[props.metadataKey][props.metadataValues[props.metadataKey].length - 1] :
                      e.target.value
                  newFilter[props.metadataKey]['range']['to'] = parseFloat(val)
                  props.storePayload({
                    activeFilters: newFilter,
                  })
                }}
                className={`number-input-component ${isRangeInvalid() ? 'number-value-input-warning': ''}`}
                info={isRangeInvalid() ? 'Invalid Value' :`Maximum: ${props.metadataValues[props.metadataKey][props.metadataValues[props.metadataKey].length - 1]}`}
                min={parseFloat(props.metadataValues[props.metadataKey][0])}
                max={parseFloat(props.metadataValues[props.metadataKey][props.metadataValues[props.metadataKey].length - 1])}
              />
            </div>
          </div>
          {props.visualizations?.[props.metadataKey]?.isActive && <div
            className='number-value-visualization'
          >
            <div
              className='number-value-visualization-row'
            >
              <span>Min. Saturation</span>
              <span>Max. Saturation</span>
            </div>
            <img
              src={gradientIcon}
              className='number-value-visualization-gradient'
            />
            <div
              className='number-value-visualization-row'
            >
              <span>Null {`(40% Opacity)`}</span>
              <span><img src={gradientNodeIcon}/></span>
            </div>
          </div>}
        </>
      )
    } else { // date
      return (
        <>
          <div
            className='number-value-row'
          >
            <div
              className='number-value-input'
            >
              <Input
                name={`${props.metadataKey}-num-min`}
                id={`${props.metadataKey}-num-min`}
                inputType={'datetime-local'}
                theme={props.theme}
                label='Minimum'
                placeholder={'Enter Value'}
                value={`${props.activeFilters[props.metadataKey]?.range?.from !== undefined ? props.activeFilters[props.metadataKey]?.range?.from: ''}`}
                onChange={(e) => {
                  const newFilter = JSON.parse(JSON.stringify(props.activeFilters))
                  const val = !(/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}/.test(e.target.value) && e.target.value.length < 21) ?
                    e.target.value :
                    e.target.value < props.metadataValues[props.metadataKey][0] ?
                      props.metadataValues[props.metadataKey][0] :
                      e.target.value > props.metadataValues[props.metadataKey][props.metadataValues[props.metadataKey].length - 1] ?
                        props.metadataValues[props.metadataKey][props.metadataValues[props.metadataKey].length - 1] :
                        e.target.value
                  newFilter[props.metadataKey]['range']['from'] = val
                  props.storePayload({
                    activeFilters: newFilter,
                  })
                }}
                className={`number-input-component ${isRangeInvalid() ? 'number-value-input-warning': ''}`}
                info={isRangeInvalid() ? 'Invalid Value' :`Minimum: ${props.metadataValues[props.metadataKey][0]}`}
                min={parseFloat(props.metadataValues[props.metadataKey][0])}
                max={parseFloat(props.metadataValues[props.metadataKey][props.metadataValues[props.metadataKey].length - 1])}
              />
            </div>
            <div className='number-value-dash'>
              <Icon
                icon='minus-outline'
              />
            </div>
            <div
              className='number-value-input'
            >
              <Input
                name={`${props.metadataKey}-num-max`}
                id={`${props.metadataKey}-num-max`}
                inputType={'datetime-local'}
                theme={props.theme}
                label='Maximum'
                placeholder={'Enter Value'}
                value={`${props.activeFilters[props.metadataKey]?.range?.to !== undefined ? props.activeFilters[props.metadataKey]?.range?.to: ''}`}
                onChange={(e) => {
                  const newFilter = JSON.parse(JSON.stringify(props.activeFilters))
                  const val = !(/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}/.test(e.target.value) && e.target.value.length < 21) ?
                    e.target.value :
                    e.target.value < props.metadataValues[props.metadataKey][0] ?
                      props.metadataValues[props.metadataKey][0] :
                      e.target.value > props.metadataValues[props.metadataKey][props.metadataValues[props.metadataKey].length - 1] ?
                        props.metadataValues[props.metadataKey][props.metadataValues[props.metadataKey].length - 1] :
                        e.target.value
                  newFilter[props.metadataKey]['range']['to'] = val
                  props.storePayload({
                    activeFilters: newFilter,
                  })
                }}
                className={`number-input-component ${isRangeInvalid() ? 'number-value-input-warning': ''}`}
                info={isRangeInvalid() ? 'Invalid Value' :`Maximum: ${props.metadataValues[props.metadataKey][props.metadataValues[props.metadataKey].length - 1]}`}
                min={parseFloat(props.metadataValues[props.metadataKey][0])}
                max={parseFloat(props.metadataValues[props.metadataKey][props.metadataValues[props.metadataKey].length - 1])}
              />
            </div>
          </div>
          {props.visualizations?.[props.metadataKey]?.isActive && <div
            className='number-value-visualization'
          >
            <div
              className='number-value-visualization-row'
            >
              <span>Min. Saturation</span>
              <span>Max. Saturation</span>
            </div>
            <img
              src={gradientIcon}
              className='number-value-visualization-gradient'
            />
            <div
              className='number-value-visualization-row'
            >
              <span>Null {`(40% Opacity)`}</span>
              <span><img src={gradientNodeIcon}/></span>
            </div>
          </div>}
        </>
      )
    }
    return (<>{filterInputType}</>)
  }

  function toggleFilterInputOpen() {
    setIsFilterInputOpen(!isFilterInputOpen)
    setTimeout(() => {
      const windowHeight = document.getElementById('filter-content').clientHeight
      const bottomPos = document.getElementById(`input-${props.metadataKey}`).getBoundingClientRect().bottom
      if (bottomPos > windowHeight) {
        document.getElementById('filter-content').scrollTo({
          top: document.getElementById(`input-${props.metadataKey}`).getBoundingClientRect().bottom,
          behavior: 'smooth',
        })
      }
    }, 1)
  }

  return (
    <div className='filter-input' id={`input-${props.metadataKey}`}>
      <div className='filter-input-header'>
        <div className='filter-input-header-label'>
          {props.metadataKey}
        </div>
        <Switch
          label={'Visualize'}
          checked={props.visualizations?.[props.metadataKey]?.isActive}
          onChange={(e) => {
            // new to pass toggleState
            let currentActiveVisualization = null
            const activeSetTypeInit = Helpers.getInputType(props.metadataValues[props.metadataKey][0])
            const activeSetType = activeSetTypeInit === 'date'? 'number': activeSetTypeInit
            const metaKeys = Object.keys(props.metadataValues)
            for (let keyIndex = 0; keyIndex < metaKeys.length; keyIndex++) {
              const keyTypeInit = Helpers.getInputType(props.metadataValues[metaKeys[keyIndex]][0])
              const keyType = keyTypeInit === 'date'? 'number': keyTypeInit
              if (props.metadataKey !== metaKeys[keyIndex] && keyType === activeSetType && props.visualizations[metaKeys[keyIndex]]['isActive']) {
                // not same key && same type && other is active
                currentActiveVisualization = metaKeys[keyIndex]
              }
            }
            if (currentActiveVisualization && e.target.checked) {
              props.storePayload({
                isReplaceVisualWarningVisible: true,
                visualFrom: currentActiveVisualization,
                visualTo: props.metadataKey,
                visualType: activeSetType,
              })
            } else {
              props.toggleVisualization(props.metadataKey, e.target.checked, activeSetType)
            }
          }}
          reversed={true}
          theme={props.theme}
        />
        <div
          className='filter-input-header-arrow'
          onClick={toggleFilterInputOpen}
        >
          <Icon
            icon={isFilterInputOpen ? 'chevron-up-outline': 'chevron-down-outline'}
            key={isFilterInputOpen ? `up-${props.metadataKey}`: `down-${props.metadataKey}`}
          />
        </div>
      </div>
      {isFilterInputOpen &&
        <div className='filter-input-body'>
          {getBody()}
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  metadata: state.userReducer.metadata,
  metadataValues: state.userReducer.metadataValues,
  activeFilters: state.userReducer.activeFilters,
  theme: state.userReducer.theme,
  visualizations: state.userReducer.visualizations,
})

export default connect(
    mapStateToProps,
    {storePayload, toggleVisualization},
)(FilterInput)
