// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.graph-settings {
  padding: 8px;
}

.graph-settings .section-block {
  margin-bottom: 8px;
}

.graph-settings .section-title {
  font-size: 1em;
}

.graph-settings .section-content {
  font-size: .8em;
  margin-left: 8px;
}

.graph-settings .section-follow-up {
  font-size: .7em;
  margin-left: 16px;
}

.graph-settings h3 {
  font-family: var(--font-families-stylized);
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/styles/GraphSettings.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,0CAA0C;EAC1C,gBAAgB;AAClB","sourcesContent":[".graph-settings {\n  padding: 8px;\n}\n\n.graph-settings .section-block {\n  margin-bottom: 8px;\n}\n\n.graph-settings .section-title {\n  font-size: 1em;\n}\n\n.graph-settings .section-content {\n  font-size: .8em;\n  margin-left: 8px;\n}\n\n.graph-settings .section-follow-up {\n  font-size: .7em;\n  margin-left: 16px;\n}\n\n.graph-settings h3 {\n  font-family: var(--font-families-stylized);\n  font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
