/* eslint-disable max-len */
import Helpers from '../Helpers'
import types from '../types'

export const exportGraph = (database) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const {vkgDomain, authForm, userId, selectedGraphId} = userReducer
  const {authKey, orgId, licenseKey} = authForm
  if (!vkgDomain) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        nodes: {},
        isLoading: false,
        userMessage: 'Your organization doesn\'t have VKG access.',
        notificationType: 2,
        notificationIcon: 'check',
        isNotificationVisible: true,
      },
    })
  } else if (!(userReducer.userData?.vkgAccess?.vkgs?.includes('read') || userReducer.userData?.vkgAccess?.[Helpers.encodeVKGId(selectedGraphId)]?.includes('read'))) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: false,
        userMessage: 'You don\'t have read VKG access.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  } else {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: true,
      },
    })
    const endpoint = vkgDomain + `/api/vkgs/${selectedGraphId}/export`
    const headers = {'userId': userId, 'authKey': authKey, 'orgId': orgId, 'Authorization': `Bearer ${licenseKey}`}
    const date = new Date()
    const fileName = `${selectedGraphId}_${date.getMonth()+1}-${date.getDate()}-${date.getFullYear()}_${date.getTime()}.csv`
    Helpers.fetchPostJsonDownloadCSV(
        endpoint,
        headers,
        {
          'filename': fileName,
          'includeTsne': true,
          'includeMetadata': true,
        },
        fileName,
    )
        .then((res) => {
          if (!userReducer.userMessage && res?.status !== 200) {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                // eslint-disable-next-line max-len
                isLoading: false,
                userMessage: `Error: ${res?.message}`,
                notificationType: 2,
                notificationIcon: 'warning',
                isNotificationVisible: true,
              },
            })
          } else {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                isLoading: false,
              },
            })
          }
        })
        .catch((error) => {
          console.log(error)
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              // eslint-disable-next-line max-len
              isLoading: false,
              userMessage: '',
              notificationType: 2,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            },
          })
        })
  }
}
