// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-select {
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  position: relative;
}

.color-option {
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border: 1px solid #f2f2f2;
  border-radius: 24px;
  cursor: pointer;
}

.color-option-empty {
  border: 1px dashed #f2f2f2 !important;
}

.color-option-selected {
  border: 1px solid var(--colors-brand-gold);
}

.color-option-section {
  position: fixed;
  top: calc(50vh - 48px);
  left: 408px;
  width: fit-content;
  background-color: var(--colors-neutral-gray15);
  box-sizing: border-box;
  padding: var(--spacing-04) var(--spacing-04);
  border-radius: var(--spacing-02);
}

.color-option-section .color-header {
  color: var(--colors-neutral-light);
  font-family: var(--font-families-stylized);
  font-size: 12px;
  padding-bottom: var(--spacing-03);
}

.color-option-section .color-body {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: var(--spacing-04);
}`, "",{"version":3,"sources":["webpack://./src/styles/ColorSelect.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,WAAW;EACX,kBAAkB;EAClB,8CAA8C;EAC9C,sBAAsB;EACtB,4CAA4C;EAC5C,gCAAgC;AAClC;;AAEA;EACE,kCAAkC;EAClC,0CAA0C;EAC1C,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,aAAa;EACb,eAAe;EACf,WAAW;EACX,sBAAsB;AACxB","sourcesContent":[".color-select {\n  width: 24px;\n  height: 24px;\n  box-sizing: border-box;\n  position: relative;\n}\n\n.color-option {\n  width: 24px;\n  height: 24px;\n  box-sizing: border-box;\n  border: 1px solid #f2f2f2;\n  border-radius: 24px;\n  cursor: pointer;\n}\n\n.color-option-empty {\n  border: 1px dashed #f2f2f2 !important;\n}\n\n.color-option-selected {\n  border: 1px solid var(--colors-brand-gold);\n}\n\n.color-option-section {\n  position: fixed;\n  top: calc(50vh - 48px);\n  left: 408px;\n  width: fit-content;\n  background-color: var(--colors-neutral-gray15);\n  box-sizing: border-box;\n  padding: var(--spacing-04) var(--spacing-04);\n  border-radius: var(--spacing-02);\n}\n\n.color-option-section .color-header {\n  color: var(--colors-neutral-light);\n  font-family: var(--font-families-stylized);\n  font-size: 12px;\n  padding-bottom: var(--spacing-03);\n}\n\n.color-option-section .color-body {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  gap: var(--spacing-04);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
