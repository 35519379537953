/* eslint-disable max-len */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

import {Modal2, Icon, TabContainer} from '@lazarusai/forms-ui-components';
import UserSettings from './UserSettings';
import GraphSettings from './GraphSettings';
import PermissionSettings from './PermissionSettings';

import PermissionSideTab from './PermissionSideTab'

import Helpers from '../Helpers'

import '../styles/Settings.css'
import {storePayload} from '../actions/storePayload';

function Settings(props) {
  const [isModalShowing, setIsModalShowing] = useState(false)
  const [tabState, setTabState] = useState('user')
  const [canAccessPermissions, setCanAccessPermissions] = useState(false)

  function closeSettingsModal() {
    setIsModalShowing(false)
    setTabState('user')
  }

  function toggleSettingsModal() {
    setIsModalShowing(!isModalShowing)
  }

  useEffect(() => {
    if (props.usersObj && props.user) {
      const newCanAccessPermissions =
        props.usersObj?.[props.user.uid]?.permissions?.setPermissions !== undefined ?
          props.usersObj?.[props.user.uid]?.permissions?.setPermissions :
          props.usersObj?.[props.user.uid]?.role === 'admin'
      setCanAccessPermissions(newCanAccessPermissions)
    }
  }, [props.usersObj, props.user])

  function changeUserPermissions(permissions, id, currentRole, vkgAccess) {
    const newUsersObj = props.usersObj
    newUsersObj[id].role = currentRole
    props.storePayload({usersObj: newUsersObj})
    const url = process.env.REACT_APP_URL + 'update/user'
    const data = {
      userId: id,
      permissions: permissions,
      vkgAccess: Helpers.encodeVKGAccess(vkgAccess),
      role: currentRole,
    }
    const headers = {
      userId: props.user.uid,
      orgId: props.authForm.orgId,
      authKey: props.authForm.authKey,
    }
    return Helpers.fetchPostJsonWithStatus(url, data, headers)
  }

  const saveVKGPermissions = (value, id, currentRole, vkgAccess) => {
    props.storePayload({
      isPermissionsModalShowing: false,
    })
    changeUserPermissions(value, id, currentRole, vkgAccess)
        .then(async ([resPromise, status]) => {
          const res = await resPromise
          if (status >= 200 && status < 300) {
            props.storePayload({
              userMessage: `Permissions updated successfully${res.message ? ': '+res.message : '.'}`,
              notificationType: 2,
              notificationIcon: 'check',
              isNotificationVisible: true,
            })
          } else {
            props.storePayload({
              userMessage: `Error updating permissions${res.message ? ': '+res.message : '.'}`,
              notificationType: 1,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            })
          }
        })
        .catch((error) => {
          console.log(error)
          props.storePayload({
            userMessage: 'Error updating permissions.',
            notificationType: 1,
            notificationIcon: 'warning',
            isNotificationVisible: true,
          })
        })
  }

  return (
    <>
      <div
        title='Settings'
        className='has-title-right icon-wrapper settings-wrapper'
      >
        <Icon
          icon='settings-2-outline'
          onClick={toggleSettingsModal}
        />
      </div>
      <Modal2
        isVisible={isModalShowing}
        style={{
          height: 'min(100vh, 800px)',
          width: 'min(100vw, 1000px)',
        }}
        contentStyle={{
          height: '100%',
        }}
        theme={'dark'}
        onDismiss={closeSettingsModal}
        outsideClickDismisses={true}
        showClose={true}
        title={'Settings'}
        closeId={'settings-close'}
      >
        <div className='settings-card-wrapper' style={{width: 'calc(100%)'}}>
          <TabContainer
            theme={'dark'}
            tabContent={{
              'user': {
                title: 'User',
                isDisabled: false,
                render: (
                  <div className='container-wrapper'>
                    <UserSettings />
                  </div>
                ),
              },
              'graph': {
                title: 'Graph',
                isDisabled: false,
                render: (
                  <div className='container-wrapper'>
                    <GraphSettings />
                  </div>
                ),
              },
              'permissions': {
                title: 'Permissions',
                isDisabled: !canAccessPermissions,
                render: (
                  <div className='container-wrapper'>
                    <PermissionSettings />
                  </div>
                ),
              },
            }}
            activeTab={tabState}
            style={{height: '100%'}}
            canChangeTab={() => true}
            onTabChange={(tabName) => {
              setTabState(tabName)
            }}
          />
        </div>
      </Modal2>
      {props.isPermissionsModalShowing &&
        <PermissionSideTab
          email={props.permissionsEmail}
          id={props.permissionsID}
          permissionUser={props.permissionsUser}
          isVisible={props.isPermissionsModalShowing}
          onClose={() => {
            props.storePayload({
              isPermissionsModalShowing: false,
            })
          }}
          onSave={saveVKGPermissions}
        />
      }
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  usersObj: state.userReducer.usersObj,
  users: state.userReducer.users,
  user: state.userReducer.user,
  permissionsID: state.userReducer.permissionsID,
  permissionsEmail: state.userReducer.permissionsEmail,
  permissionsUser: state.userReducer.permissionsUser,
  isPermissionsModalShowing: state.userReducer.isPermissionsModalShowing,
  userData: state.userReducer.userData,
  theme: state.userReducer.theme,
  authForm: state.userReducer.authForm,
})

export default connect(
    mapStateToProps,
    {storePayload},
)(Settings)
