/* eslint-disable max-len */
import types from '../types'
import 'firebase/compat/auth'
import 'firebase/compat/database'
import {getAuthKey} from './getAuthKey'
import {getLicenseKey} from './getLicenseKey'
import {getOrgDetails} from './getOrgDetails'
import {getCustomDomain} from './getCustomDomain'
import {getUserData} from './getUserData'
import {getUsers} from './getUsers'
import {signOut} from './signOut'
import {getSearchSize} from './getSearchSize'
import {validateLicenseKey} from './validateLicenseKey'

export const onSignInSuccess = (user, database) => async (dispatch, getState) => {
  const authForm = getState().userReducer.authForm
  if (user && user.uid) {
    let orgId
    const orgIdRef = database.ref(`users/${user.uid}/organization/orgId`)
    orgIdRef.get()
        .then(async (snapshot) => {
          const val = snapshot.val()
          orgId = val
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              authForm: {
                ...authForm,
                orgId: orgId,
              },
            },
          })
          // validate org license key
          const licenseKey = await getLicenseKey(database, orgId)
          const isValidLicenseKey = await validateLicenseKey(licenseKey)
          if (!isValidLicenseKey) {
            dispatch({ // org does not have valid license
              type: types.STORE_PAYLOAD,
              payload: {
                isLoading: false,
                userMessage: 'Your organization does not have a valid license.',
                notificationType: 3,
                notificationIcon: 'warning',
                isNotificationVisible: true,
              },
            })
            dispatch(signOut(false))
          } else {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                authForm: {
                  ...authForm,
                  orgId: orgId,
                  licenseKey: licenseKey,
                },
              },
            })

            const orgDetails = await getOrgDetails(database, orgId)
            const userData = await getUserData(database, orgId, user.uid)

            // has VKG read access
            if (userData?.vkgAccess) {
              dispatch(getAuthKey(database, orgId))
              dispatch(getCustomDomain(database, orgId))
              dispatch(getUsers(database, orgId))
              dispatch(getSearchSize(database, user.uid))
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  user,
                  orgId: orgId,
                  isLoading: false,
                  isSignedIn: true,
                  orgDetails: orgDetails,
                  userData: userData,
                },
              })
            } else { // no VKG access
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  isLoading: false,
                  userMessage: 'You don\'t have VKG access. Ask your organization admin to grant access.',
                  notificationType: 3,
                  notificationIcon: 'warning',
                  isNotificationVisible: true,
                },
              })
              dispatch(signOut(false))
            }
          }
        })
        .catch((err) => {
          console.log('error', err)
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              isLoading: false,
              isSignedIn: false,
              userMessage: 'Sign in failure.',
              notificationType: 2,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            },
          })
        })
  }
}
