/* methods used in multiple components */
/* eslint-disable max-len */

const Helpers = {
  time() {
    const now = new Date()
    const hour = now.getHours()
    const min = now.getMinutes()
    return `${hour}:${min}`
  },

  async fetchPostJson(url, headers, data) {
    const myHeaders = new Headers(headers)
    myHeaders.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',
      connection: 'keep-alive',
    }

    const response = await fetch(url, requestOptions)
    // parses JSON response into native JavaScript objects
    return response.json();
  },

  async fetchPostJsonWithStatus(url, data, headers = {}) {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    const headerKeys = Object.keys(headers)
    if (headerKeys?.length > 0) {
      headerKeys.forEach((key) => {
        myHeaders.append(key, headers[key])
      })
    }
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',
    }

    const response = await fetch(url, requestOptions)
    // parses JSON response into native JavaScript objects
    return [response.json(), response.status]
  },

  async fetchPostJsonDownloadCSV(url, headers, data, fileName) {
    const myHeaders = new Headers(headers)
    myHeaders.append('Content-Type', 'application/json')

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',
      connection: 'keep-alive',
    }

    const response = await fetch(url, requestOptions)
    // parses JSON response into native JavaScript objects
    this.downloadFile(await response.text(), 'text/csv', fileName);
    return response;
  },

  async fetchPutJson(url, headers, data) {
    const myHeaders = new Headers(headers)
    myHeaders.append('Content-Type', 'application/json')
    const requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',
    }
    const response = await fetch(url, requestOptions)
    return response.json();
    // parses JSON response into native JavaScript objects
  },

  async fetchDeleteJson(url, headers, data) {
    const myHeaders = new Headers(headers)
    myHeaders.append('Content-Type', 'application/json')
    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',
    }
    const response = await fetch(url, requestOptions)
    return [response.ok, response.json()];
    // parses JSON response into native JavaScript objects
  },

  async fetchPostMultipart(url, headers, formdata) {
    const myHeaders = new Headers(headers)
    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
      connection: 'keep-alive',
    }

    const response = await fetch(url, requestOptions)
    // parses JSON response into native JavaScript objects
    return response.json();
  },

  async fetchGet(url, headers) {
    const myHeaders = new Headers(headers)
    myHeaders.append('Content-Type', 'application/json')
    const requestOptions = {
      headers: myHeaders,
      redirect: 'follow',
    }

    const response = await fetch(url, requestOptions)
    // parses JSON response into native JavaScript objects
    return response.json();
  },

  async fetchGetJson(url, headers, data) {
    const myHeaders = new Headers(headers)
    myHeaders.append('Content-Type', 'application/json')
    const requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }
    const addition = Object.keys(data).length ? '?' + new URLSearchParams(data) : ''
    const response = await fetch(url + addition, requestOptions)
    return response.json()
  },

  copyToClipBoard(copyText, setIsLinkCoppied) {
    navigator.permissions.query({name: 'clipboard-write'}).then((result) => {
      if (result.state === 'granted' || result.state === 'prompt') {
        /* write to the clipboard now */
        navigator.clipboard.writeText(copyText).then(() => {
          /* clipboard successfully set */
          setIsLinkCoppied(true)
        }, function() {
          /* clipboard write failed */
          setIsLinkCoppied(false)
        })
      }
    })
  },

  downloadFile(fileText, fileType, fileName) {
    const blob = new Blob([fileText], {type: fileType});
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('href', url)
    a.setAttribute('download', fileName);
    a.click()
  },

  timeStampToDate(timestamp) {
    const dateObj = new Date(timestamp)
    return (
      dateObj.getMonth() + 1 +
      '/' + dateObj.getDate() +
      '/' + dateObj.getFullYear()
    )
  },

  timeStampToTime(timestamp) {
    const dateObj = new Date(timestamp)
    const hours = dateObj.getHours().toString()
    const minutes = dateObj.getMinutes().toString()
    const formattedHours = hours.length === 2 ? hours : '0' + hours
    const formattedMinutes = minutes.length === 2 ? minutes : '0' + minutes
    return (
      `${formattedHours}:${formattedMinutes}`
    )
  },

  formatDate(str) {
    if (str) {
      const dashB = str.slice(5, str.length) + '/' + str.slice(0, 4)
      return dashB.replace('-', '/')
    } else {
      return ''
    }
  },

  roundHundredths(val) {
    return Math.ceil(val * 100) / 100;
  },

  /* Used to trigger callback after typing has stopped for pause length */
  toggleTyping(timeOfType, callback, pauseLength = 2000) {
    setTimeout(() => {
      const now = Date.now()
      if ((now - timeOfType) >= pauseLength) {
        callback()
      }
    }, pauseLength);
    return false
  },

  historyPush(newPath) {
    window.history.pushState({path: newPath}, '', newPath);
  },

  truncateString(str, limit) {
    if (str?.length > limit) {
      return str.slice(0, limit) + '...'
    } else {
      return str
    }
  },

  capitalizeFirstLetter(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
    return ''
  },

  openLinkInNewTab(link) {
    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: link,
    }).click();
  },

  getInputType(value) {
    if (typeof value === 'boolean' || (typeof value === 'string' && (value.toLowerCase() === 'true' || value.toLowerCase() === 'false'))) {
      return 'boolean'
    } else if (typeof value === 'string' &&
      (/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}/.test(value) ||
        /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}/.test(value)
      )
    ) {
      return 'date'
    } else if (typeof value === 'number' ||
      (!isNaN(parseFloat(value)) &&
      /^-?\d+\.?\d*$/.test(value))) {
      return 'number'
    } else {
      return 'string'
    }
  },

  getActiveFilters(activeFilters) {
    const filterKeys = Object.keys(activeFilters || {})
    const activeList = []
    for (let i = 0; i < filterKeys.length; i++) {
      if (Object.keys(activeFilters[filterKeys[i]]).includes('range')) { // range filter
        const fromVal = activeFilters[filterKeys[i]].range?.from
        const toVal = activeFilters[filterKeys[i]].range?.to
        const fromType = Helpers.getInputType(fromVal)
        const toType = Helpers.getInputType(toVal)
        if (((fromType === 'date' && toType === 'date') || (fromType === 'number' && toType === 'number')) && (parseFloat(fromVal) <= parseFloat(toVal))) {
          activeList.push({
            'key': filterKeys[i],
            'value': `${activeFilters[filterKeys[i]].range?.from} - ${activeFilters[filterKeys[i]].range?.to}`,
          })
        }
      } else { // list filter
        if (activeFilters[filterKeys[i]].length) {
          for (let valIndex = 0; valIndex < activeFilters[filterKeys[i]].length; valIndex++) {
            activeList.push({
              'key': filterKeys[i],
              'value': `${activeFilters[filterKeys[i]][valIndex]}`,
            })
          }
        }
      }
    }
    return activeList
  },

  processCSVCell(cellValue) {
    const doubleQuotes = cellValue.split('').map((char) => {
      if (char === '"') {
        return '""'
      }
      return char
    }).join('')
    if (doubleQuotes.includes(',') || doubleQuotes.includes('\n')) {
      return `"${doubleQuotes}"`
    }
    return doubleQuotes
  },

  checkJsonStructure(potentialJSON) {
    try {
      JSON.parse(potentialJSON)
    } catch (e) {
      return false
    }
    return true
  },

  checkMetadataTypes(potentialMetadataString) {
    const BE_METADATA_TYPES = ['string', 'datetime', 'number', 'any']
    const metadata = JSON.parse(potentialMetadataString)
    return Object.keys(metadata)
        .map((metadatum) => metadata[metadatum]?.['type'])
        .every((type) => BE_METADATA_TYPES.includes(type) || !type)
  },

  encodeVKGId(vkgId) {
    if (vkgId) {
      return vkgId.replaceAll('.', '<<period>>')
          .replaceAll('$', '<<dollar>>')
          .replaceAll('#', '<<hash>>')
          .replaceAll('[', '<<openbracket>>')
          .replaceAll(']', '<<closebracket>>')
          .replaceAll('/', '<<slash>>')
    }
  },

  decodeVKGId(encodedVkgId) {
    return encodedVkgId.replaceAll('<<period>>', '.')
        .replaceAll('<<dollar>>', '$')
        .replaceAll('<<hash>>', '#')
        .replaceAll('<<openbracket>>', '[')
        .replaceAll('<<closebracket>>', ']')
        .replaceAll('<<slash>>', '/')
  },

  encodeVKGAccess(vkgAccess) {
    const newVKGAccess = {}
    const vkgAccessKeys = Object.keys(vkgAccess || {})
    for (let vIndex = 0; vIndex < vkgAccessKeys.length; vIndex++) {
      newVKGAccess[this.encodeVKGId(vkgAccessKeys[vIndex])] = vkgAccess[vkgAccessKeys[vIndex]]
    }
    return newVKGAccess
  },

  decodeVKGAccess(vkgAccess) {
    const newVKGAccess = {}
    const vkgAccessKeys = Object.keys(vkgAccess || {})
    for (let vIndex = 0; vIndex < vkgAccessKeys.length; vIndex++) {
      newVKGAccess[this.decodeVKGId(vkgAccessKeys[vIndex])] = vkgAccess[vkgAccessKeys[vIndex]]
    }
    return newVKGAccess
  },
}

export default Helpers
