/* eslint-disable max-len */
import Helpers from '../Helpers'
import types from '../types'

export const updateNode = (database, vkgId, node_id, new_value, new_metadata) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const {vkgDomain, authForm, userId} = userReducer
  const {authKey, orgId, licenseKey} = authForm
  if (!vkgDomain) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        nodes: {},
        isLoading: false,
        userMessage: 'Your organization doesn\'t have VKG access.',
        notificationType: 2,
        notificationIcon: 'check',
        isNotificationVisible: true,
      },
    })
  } else if (!(userReducer.userData?.vkgAccess?.vkgs?.includes('write') || userReducer.userData?.vkgAccess?.[Helpers.encodeVKGId(vkgId)]?.includes('write'))) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: false,
        userMessage: 'Your don\'t have write VKG access.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  } else {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: true,
      },
    })
    const endpoint = vkgDomain + `/api/vkgs/${vkgId}/nodes/json`
    const headers = {'userId': userId, 'authKey': authKey, 'orgId': orgId, 'Authorization': `Bearer ${licenseKey}`}
    const body = {'nodes': {}}
    body['nodes'][node_id] = {'text': new_value, 'metadata': new_metadata}
    if (!new_metadata) {
      delete body['nodes'][node_id.split(':')[1]]['metadata']
    }
    Helpers.fetchPutJson(endpoint, headers, body)
        .then((res) => {
          if (res['status'] === 'SUCCESS' && !res.failedNodes) {
            const nodes = userReducer.nodes
            const searchNodes = userReducer.searchNodes
            const multiselectNodes = userReducer.multiselectNodes
            const newStructure = Object.keys(nodes[node_id]).includes('text')
            const oldValue = newStructure? nodes[node_id]['text']: nodes[node_id][0]
            if (newStructure) {
              nodes[node_id]['text'] = new_value
              nodes[node_id]['metadata'] = new_metadata
            } else {
              nodes[node_id][0] = new_value
            }
            if (searchNodes) {
              const newSearchNodes = searchNodes.map((searchNode) => {
                if (searchNode.id === oldValue) {
                  searchNode.id = new_value
                }
                return searchNode
              })
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  searchNodes: newSearchNodes,
                },
              })
            }
            if (multiselectNodes) {
              const newMultiselectNodes = multiselectNodes.map((multiselectNode) => {
                if (multiselectNode.id === oldValue) {
                  multiselectNode.id = new_value
                }
                return multiselectNode
              })
              dispatch({
                type: types.STORE_PAYLOAD,
                payload: {
                  multiselectNodes: newMultiselectNodes,
                },
              })
            }
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                isLoading: false,
                nodes: JSON.parse(JSON.stringify(nodes)),
                userMessage: `Successfully updated node ${node_id}.`,
                notificationType: 2,
                notificationIcon: 'check',
                isNotificationVisible: true,
              },
            })
          } else {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                isLoading: false,
                userMessage: `Failed to update node ${node_id}.`,
                notificationType: 2,
                notificationIcon: 'warning',
                isNotificationVisible: true,
              },
            })
          }
        })
        .catch((error) => {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              isLoading: false,
              userMessage: `Failed to update node ${node_id}.`,
              notificationType: 2,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            },
          })
        })
  }
}
