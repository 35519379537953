/* eslint-disable max-len */
import React from 'react'
import {connect} from 'react-redux'

import '../styles/GraphCard.css'

import Share from '../images/share-outline.svg'
import {Icon, Button} from '@lazarusai/forms-ui-components'

import {deleteGraph} from '../actions/deleteGraph'
import {storePayload} from '../actions/storePayload'

import Helpers from '../Helpers'

/**
 * GraphCards
 * - these cards show the info on the different vkgs available
 * - cards are also clickable to open that vkg
 */

function GraphCard(props) {
  return (
    <div className={'graph-card' + ((props.isHighlighted) ? ' highlighted-card' : '')} onClick={props.onClick}>
      <div className='graph-name'>
        {props.name}
      </div>
      <div className='graph-info'>
        <img className='graph-icon' alt='' src={Share}/>
        <div className='graph-node-numbers'>
          {props.nodes} nodes
        </div>
      </div>
      <div className='graph-id'>
        ID: {props.graphId}
      </div>
      <div className='graph-actions'>
        <Button
          type={10}
          text={'Delete'}
          theme={'dark'}
          onClick={(e) => {
            e.stopPropagation()
            const deleteConfirm = confirm(`Click OK to confirm the removal of the ${props.name} graph.`)
            if (deleteConfirm) {
              props.deleteGraph(props.database, props.name)
            }
          }}
          icon={
            <Icon
              icon='trash-outline'
            />
          }
          iconPosition='left'
          iconJustify='center'
          disabled={!(props.userData?.vkgAccess?.vkgs?.includes('write') || props.userData?.vkgAccess?.[Helpers.encodeVKGId(props.graphId)]?.includes('write'))}
        />
        <Button
          type={6}
          text={'Add Data'}
          theme={'dark'}
          onClick={(e) => {
            e.stopPropagation()
            props.storePayload({
              isModal: true,
              isModalShowing: true,
              modalMode: 'add',
              uploadGraphId: props.graphId,
            })
          }}
          icon={
            <Icon
              icon='upload-outline'
            />
          }
          iconPosition='left'
          iconJustify='center'
          disabled={!(props.userData?.vkgAccess?.vkgs?.includes('write') || props.userData?.vkgAccess?.[Helpers.encodeVKGId(props.graphId)]?.includes('write'))}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  userData: state.userReducer.userData,
})

export default connect(
    mapStateToProps,
    {deleteGraph, storePayload},
)(GraphCard)
