/* eslint-disable max-len */
import React, {useState} from 'react'
import {connect} from 'react-redux'

import '../styles/FilterUpload.css'
import {Icon, Card, Button} from '@lazarusai/forms-ui-components'

import {storePayload} from '../actions/storePayload'

function FilterUpload({
  closeUpload = () => {},
  ...props
}) {
  const [fileDrag, setFileDrag] = useState(false)
  const [fileName, setFileName] = useState(null)
  const [numberFilters, setNumberFilters] = useState(null)
  const [filterDateString, setFilterDateString] = useState(null)
  const [fileContents, setFileContents] = useState(null)

  async function onChangeFile(e) {
    const file = await e.target?.files?.length ?
      e.target.files[0] :
      e.dataTransfer.files.length ?
        e.dataTransfer.files[0] :
        null
    if (file) {
      setFileName(file.name)
      setFilterDateString(new Date(file?.lastModified).toString().substring(4, 24))
      const reader = new FileReader();
      reader.onload = function(e) {
        const rawContent = e.target.result
        const jsonContent = JSON.parse(rawContent)
        const contentKeys = Object.keys(jsonContent['filters'])
        setNumberFilters(contentKeys.length)
        setFileContents(jsonContent)
      }
      reader.readAsText(file)
    }
  }

  function dragOver(e) {
    e.preventDefault();
    setFileDrag(true);
  }

  function dragEnter(e) {
    e.preventDefault();
    setFileDrag(true);
  }

  function dragDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setFileDrag(false);
    onChangeFile(e)
  }

  function dragLeave(e) {
    e.preventDefault()
    setFileDrag(false)
  }

  return (
    <div
      className='filter-upload'
    >
      <div>
        {fileContents &&
        <Card
          className='uploaded-card'
          title={fileName}
          theme={props.theme}
        >
          <div className='number-row'>
            <Icon icon='options-2-outline'/>
            <span>{numberFilters} filters</span>
          </div>
          <div className='date-row'>
            Created: {filterDateString}
          </div>
        </Card>
        }
      </div>
      <div>
        <label
          htmlFor='filter-input'
          onDragEnter={dragEnter}
          onDrop={dragDrop}
          onDragLeave={dragLeave}
          onDragOver={dragOver}
          className='upload-section'
        >
          <div className={`label-contents`}>
            <Icon
              icon={'upload-outline'}
            />
            <h3>Upload previously saved filter selection</h3>
            <p>This will replace the current uploaded file</p>
          </div>
        </label>
        <input
          type='file'
          id='filter-input'
          accept='.json'
          onChange={onChangeFile}
          onClick={(e) => {
            e.target.value = null
          }}
          style={{zIndex: fileDrag ? '5' : '-1'}}
          onDrop={dragDrop}
          onDragLeave={dragLeave}
        />
      </div>
      <div className='upload-footer'>
        <Button
          text={'Back'}
          theme={props.theme}
          type={6}
          iconPosition='left'
          icon={
            <Icon
              icon={'chevron-left-outline'}
            />
          }
          onClick={() => {
            closeUpload()
          }}
        />
        <Button
          text={'Update Filter'}
          theme={props.theme}
          type={8}
          disabled={fileContents ? false: true}
          iconPosition='left'
          icon={
            <Icon
              icon={'flip-2-outline'}
            />
          }
          onClick={() => {
            const confirmUpdate = confirm(`Confirm that you want to update your filters with the ${fileName} filters. This will reset your existing filters.`)
            if (confirmUpdate) {
              props.storePayload({
                activeFilters: fileContents['filters'],
                visualizations: fileContents['visuals'],
              })
              closeUpload()
            }
          }}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  theme: state.userReducer.theme,
})

export default connect(
    mapStateToProps,
    {storePayload},
)(FilterUpload)
