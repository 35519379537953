/* eslint-disable max-len */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

import {Icon, Select, SearchBar, Button, Modal2} from '@lazarusai/forms-ui-components';
import {storePayload} from '../actions/storePayload';
import {getInitialFilterState} from '../actions/getInitialFilterState';
import {toggleVisualization} from '../actions/toggleVisualization';

import '../styles/Filter.css'
import KeyValueWrapper from './KeyValueWrapper';
import FilterInput from './FilterInput';
import Helpers from '../Helpers';
import FilterUpload from './FilterUpload';

function Filter(props) {
  const [inputSortMethod, setInputSortMethod] = useState('All')
  const [inputSearchString, setInputSearchString] = useState('')
  const [activeStringVis, setActiveStringVis] = useState(null)
  const [activeBooleanVis, setActiveBooleanVis] = useState(null)
  const [activeRangeVis, setActiveRangeVis] = useState(null)
  const [isUploadShowing, setIsUploadShowing] = useState(false)

  const sortOptions = ['All', 'Boolean', 'Date/Number', 'String', 'Visualized']

  useEffect(() => {
    let newStringVis = null
    let newBooleanVis = null
    let newRangeVis = null
    const metaKeys = Object.keys(props.metadataValues)
    for (let keyIndex = 0; keyIndex < metaKeys.length; keyIndex++) {
      const keyTypeInit = Helpers.getInputType(props.metadataValues[metaKeys[keyIndex]][0])
      const keyType = keyTypeInit === 'date'? 'number': keyTypeInit
      if (keyType === 'string' && props.visualizations[metaKeys[keyIndex]]['isActive']) {
        newStringVis = metaKeys[keyIndex]
      }
      if (keyType === 'boolean' && props.visualizations[metaKeys[keyIndex]]['isActive']) {
        newBooleanVis = metaKeys[keyIndex]
      }
      if (keyType === 'number' && props.visualizations[metaKeys[keyIndex]]['isActive']) {
        newRangeVis = metaKeys[keyIndex]
      }
    }
    setActiveStringVis(newStringVis)
    setActiveBooleanVis(newBooleanVis)
    setActiveRangeVis(newRangeVis)
  }, [props.visualizations])

  function getActiveFilters(activeFilters) {
    const filterKeys = Object.keys(activeFilters)
    const activeList = []
    for (let i = 0; i < filterKeys.length; i++) {
      if (Object.keys(activeFilters[filterKeys[i]]).includes('range')) { // range filter
        const fromVal = activeFilters[filterKeys[i]].range?.from
        const toVal = activeFilters[filterKeys[i]].range?.to
        const fromType = Helpers.getInputType(fromVal)
        const toType = Helpers.getInputType(toVal)
        if (((fromType === 'date' && toType === 'date') || (fromType === 'number' && toType === 'number')) && (parseFloat(fromVal) <= parseFloat(toVal))) {
          activeList.push({
            'key': filterKeys[i],
            'value': `${activeFilters[filterKeys[i]].range?.from} - ${activeFilters[filterKeys[i]].range?.to}`,
          })
        }
      } else { // list filter
        if (activeFilters[filterKeys[i]].length) {
          for (let valIndex = 0; valIndex < activeFilters[filterKeys[i]].length; valIndex++) {
            activeList.push({
              'key': filterKeys[i],
              'value': `${activeFilters[filterKeys[i]][valIndex]}`,
            })
          }
        }
      }
    }
    return activeList
  }

  function removeFilter(filterKey, filterValString) {
    const newFilter = JSON.parse(JSON.stringify(props.activeFilters))
    if (Object.keys(newFilter[filterKey]).includes('range')) {
      newFilter[filterKey] = {'range': {}}
    } else if (newFilter[filterKey].length) {
      newFilter[filterKey] = newFilter[filterKey].filter((val) => {
        return `${val}` !== filterValString
      })
    }
    props.storePayload({
      activeFilters: newFilter,
    })
  }

  function metadataKeyToSortType(key) {
    const baseType = Helpers.getInputType(props.metadataValues[key][0])
    return sortOptions.filter((val) => {
      return val.toLowerCase().includes(baseType)
    })[0]
  }

  // function sortCompareFunction(aKey, bKey) {
  //   if (inputSortMethod === 'All') {
  //     // alpha sort
  //     return aKey < bKey ? -1 : 1
  //   } else if (inputSortMethod === 'Visualized') {
  //     // visualization sort
  //     const aVal = (props.visualizations?.[aKey]?.isActive ? 1: 0)
  //     const bVal = (props.visualizations?.[bKey]?.isActive ? 1: 0)
  //     return aVal === bVal ? 0 : aVal > bVal ? -1: 1
  //   } else {
  //     // type sort
  //     const aVal = (metadataKeyToSortType(aKey) === inputSortMethod ? 1: 0)
  //     const bVal = (metadataKeyToSortType(bKey) === inputSortMethod ? 1: 0)
  //     return aVal === bVal ? 0 : aVal > bVal ? -1: 1
  //   }
  // }

  function sortFilterFunction(aKey) {
    if (inputSortMethod === 'All') {
      // alpha sort
      return true
    } else if (inputSortMethod === 'Visualized') {
      // visualization sort
      return props.visualizations?.[aKey]?.isActive
    } else {
      // type sort
      return metadataKeyToSortType(aKey) === inputSortMethod
    }
  }

  function searchCompareFunction(aKey, bKey) {
    if (!inputSearchString?.length) {
      return 0
    }
    const aIndex = aKey.toLowerCase().indexOf(inputSearchString.toLowerCase())
    const bIndex = bKey.toLowerCase().indexOf(inputSearchString.toLowerCase())
    const aVal = aIndex > -1 ? aIndex: 100
    const bVal = bIndex > -1 ? bIndex: 100
    return aVal === bVal ? 0 : aVal < bVal ? -1: 1
  }

  function sortMetadata(metadata) {
    return Object.keys(metadata).filter(sortFilterFunction).sort(searchCompareFunction)
  }

  function dismissReplaceVisualWarning() {
    props.storePayload({
      isReplaceVisualWarningVisible: false,
      visualFrom: null,
      visualTo: null,
    })
  }

  return (
    <div className={`filter-component`}>
      <Modal2
        isVisible={props.isReplaceVisualWarningVisible}
        title={`Replace Visuals: ${props.visualFrom} to ${props.visualTo}`}
        outsideClickDismisses={true}
        contentStyle={{height: '100%', width: '100%', boxSizing: 'border-box'}}
        onDismiss={dismissReplaceVisualWarning}
        style={{
          height: 'min(400px)',
          width: 'min(600px)',
        }}
        hasLine={false}
        footer={<div className='warning-footer'>
          <Button
            text={'Cancel'}
            onClick={dismissReplaceVisualWarning}
            theme={props.theme}
            type={10}
          />
          <Button
            text={'Confirm'}
            onClick={() => {
              props.toggleVisualization(props.visualTo, true, props.visualType)
              dismissReplaceVisualWarning()
            }}
            theme={props.theme}
            type={8}
          />
        </div>}
        closeId={'filter-confirmation-close'}
      >
        Visualization is already enabled for <b>{props.visualFrom}</b> which is the same type (i.e. string, boolean, or numerical). This action will override your previous selection and visualize the current filter <b>{`(${props.visualTo})`}</b> instead.
      </Modal2>
      <div className='filter-component-body'>
        {isUploadShowing ?
        <div className={`filter-content ${isUploadShowing ? 'filter-component-upload': ''}`}>
          <FilterUpload
            closeUpload={() => {
              setIsUploadShowing(false)
            }}
          />
        </div> :
        <div className='filter-content' id='filter-content'>
          <div
            className='title-row'
          >
            <div
              className='filter-title'
            >
              Filters
            </div>
            <Select
              placeholder={'Sort'}
              theme={props.theme}
              value={inputSortMethod}
              options={sortOptions}
              onClickOption={(option) => {
                setInputSortMethod(option)
              }}
            />
          </div>
          <div
            className='filter-section'
          >
            <div
              className='search-upload-row'
            >
              <SearchBar
                isCollapsed={false}
                isCollapsible={false}
                theme={props.theme}
                placeHolder={'Search Filters'}
                searchValue={inputSearchString}
                onChange={(e) => {
                  setInputSearchString(e.target.value)
                }}
                inputId={'filter-search-input'}
              />
              <Icon
                icon={'upload-outline'}
                onClick={() => {
                  setIsUploadShowing(true)
                }}
              />
            </div>
            <div
              className='info-text'
            >
              Search or upload previously saved filters
            </div>
          </div>
          <div
            className='filter-section no-padding-bottom'
          >
            <div
              className='title-row'
            >
              <div
                className='filter-title'
              >
                Visualization:
              </div>
              <div
                className='filter-title reset-text'
                onClick={() => {
                  if (activeStringVis) {
                    props.toggleVisualization(activeStringVis, false, 'string')
                  }
                  if (activeBooleanVis) {
                    props.toggleVisualization(activeBooleanVis, false, 'boolean')
                  }
                  if (activeRangeVis) {
                    props.toggleVisualization(activeRangeVis, false, 'number')
                  }
                }}
              >
                Reset
              </div>
            </div>
            <div
              className='visualization-metadata-row'
            >
              <div
                className='metadata-title'
              >
                String
              </div>
              {(activeStringVis) ?
                <KeyValueWrapper
                  keyText={activeStringVis}
                  onClose={() => {
                    props.toggleVisualization(activeStringVis, false, 'string')
                  }}
                />:
                <div
                  className='unselected-text'
                >
                  1 section max
                </div>
              }
            </div>
            <div
              className='visualization-metadata-row'
            >
              <div
                className='metadata-title'
              >
                Boolean
              </div>
              {(activeBooleanVis) ?
                <KeyValueWrapper
                  keyText={activeBooleanVis}
                  onClose={() => {
                    props.toggleVisualization(activeBooleanVis, false, 'boolean')
                  }}
                />:
                <div
                  className='unselected-text'
                >
                  1 section max
                </div>
              }
            </div>
            <div
              className='visualization-metadata-row'
            >
              <div
                className='metadata-title'
              >
                Number/Date
              </div>
              {(activeRangeVis) ?
                <KeyValueWrapper
                  keyText={activeRangeVis}
                  onClose={() => {
                    props.toggleVisualization(activeRangeVis, false, 'number')
                  }}
                />:
                <div
                  className='unselected-text'
                >
                  1 section max
                </div>
              }
            </div>
            <div
              className='title-row'
            >
              <div
                className='filter-title'
              >
                Filter By:
              </div>
              <div
                className='filter-title reset-text'
                onClick={async () => {
                  const initFilters = await getInitialFilterState(props.metadataValues)
                  props.storePayload({
                    activeFilters: initFilters,
                    userMessage: 'Filters reset',
                    notificationType: 2,
                    notificationIcon: 'check',
                    isNotificationVisible: true,
                  })
                }}
              >
                Reset
              </div>
            </div>
            <div
              className='active-filters filter-scroll'
            >
              {props.activeFilters && getActiveFilters(props.activeFilters).map((actFilterVal, index) => {
                return (
                  <KeyValueWrapper
                    key={`${actFilterVal['key']}-${actFilterVal['value']}-${index}`}
                    keyText={actFilterVal['key']}
                    valueText={actFilterVal['value']}
                    onClose={() => {
                      removeFilter(actFilterVal['key'], actFilterVal['value'])
                    }}
                  />
                )
              })}
            </div>
          </div>
          {
            props.metadataValues && sortMetadata(props.metadataValues).map((metaKey, index) => {
              return (
                <FilterInput
                  key={`${metaKey}-${index}`}
                  metadataKey={metaKey}
                />
              )
            })
          }
          <div className='filter-footer-placeholder'></div>
          <div className='filter-footer'>
            <Button
              text={'Clear All'}
              theme={props.theme}
              type={10}
              iconPosition='left'
              icon={
                <Icon
                  icon={'trash-outline'}
                />
              }
              onClick={() => {
                const confirmClear = confirm('Are you sure you want to clear all your filters and visualizations? All of that data will be lost.')
                if (confirmClear) {
                  const newFilter = getInitialFilterState(props.metadataValues)
                  const metadataKeys = Object.keys(props.metadataValues || {})
                  const newVis = {}
                  for (let i = 0; i < metadataKeys.length; i++) {
                    newVis[metadataKeys[i]] = {
                      'isActive': false,
                    }
                  }
                  props.storePayload({
                    activeFilters: newFilter,
                    visualizations: newVis,
                  })
                  setInputSortMethod('All')
                }
              }}
            />
            <Button
              text={'Download Filters'}
              theme={props.theme}
              type={6}
              download={true}
              iconPosition='left'
              icon={
                <Icon
                  icon={'download-outline'}
                />
              }
              onClick={() => {
                const downloadObj = {
                  'filters': props.activeFilters,
                  'visuals': props.visualizations,
                }
                const blob = new Blob([JSON.stringify(downloadObj, null, 2)], {type: 'application/json'});
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.setAttribute('href', url)
                const date = new Date()
                const file_name = `${props.selectedGraphId}-Filter-${date.getMonth()+1}-${date.getDate()}-${date.getFullYear()}_${date.getTime()}.json`
                a.setAttribute('download', file_name);
                a.click()
              }}
            />
          </div>
        </div>
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  selectedGraphId: state.userReducer.selectedGraphId,
  usersObj: state.userReducer.usersObj,
  users: state.userReducer.users,
  user: state.userReducer.user,
  isFilterOn: state.userReducer.isFilterOn,
  metadata: state.userReducer.metadata,
  metadataValues: state.userReducer.metadataValues,
  theme: state.userReducer.theme,
  activeFilters: state.userReducer.activeFilters,
  visualizations: state.userReducer.visualizations,
  isReplaceVisualWarningVisible: state.userReducer.isReplaceVisualWarningVisible,
  visualFrom: state.userReducer.visualFrom,
  visualTo: state.userReducer.visualTo,
  visualType: state.userReducer.visualType,
})

export default connect(
    mapStateToProps,
    {storePayload, toggleVisualization},
)(Filter)
