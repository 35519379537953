/* eslint-disable max-len */
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import '../styles/GraphCard.css'
import {Icon, Switch} from '@lazarusai/forms-ui-components'

import {storePayload} from '../actions/storePayload'


// import {deleteGraph} from '../actions/deleteGraph'

/**
 * NodeResultCards
 */

function NodeResultCard(props) {
  const [isDataOpen, setIsDataOpen] = useState(false)
  const [isCropped, setIsCropped] = useState(false)

  function checkCropped() {
    return (document.getElementById(`data-${props.nodeId}`)?.clientHeight || 0) >= 300
  }

  function onClickExpand(e) {
    setIsDataOpen(!isDataOpen)
  }

  useEffect(() => {
    setIsCropped(checkCropped())
    // need use effect to check height after load
  }, [])

  return (
    <div className={`graph-card node-card`}>
      <div className='card-select'>
        <Switch
          checked={!props.unselectedModalNodes.includes(props.nodeId)}
          type='checkbox'
          theme='dark'
          onChange={(e) => {
            const newUnsel = [...props.unselectedModalNodes]
            if (props.unselectedModalNodes.includes(props.nodeId)) {
              newUnsel.splice(props.unselectedModalNodes.indexOf(props.nodeId), 1)
            } else {
              newUnsel.push(props.nodeId)
            }
            props.storePayload({
              unselectedModalNodes: newUnsel,
            })
          }}
        />
      </div>
      <div className={`card-data ${isDataOpen ? '' : 'card-data-close'}`} id={`data-${props.nodeId}`}>
        {props.data}
        {isCropped &&
          <div className='card-expand'>
            <Icon
              key={`${isDataOpen ? 'opened' : 'closed'}-icon`}
              icon={`${isDataOpen ? 'arrow-ios-upward-outline' : 'arrow-ios-downward-outline'}`}
              className={'cursor-pointer'}
              onClick={(e) => {
                onClickExpand(e)
              }}
            />
          </div>
        }
      </div>
      <div className='card-id'>
        {props.nodeId}
      </div>
      <div className='card-coordinates'>
        {`(${props.coordinates[0]}, ${props.coordinates[1]}, ${props.coordinates[2]})`}
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  unselectedModalNodes: state.userReducer.unselectedModalNodes,
})

export default connect(
    mapStateToProps,
    {storePayload},
)(NodeResultCard)
