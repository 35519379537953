export const getOrgDetails = (database, orgId) => {
  const dbRef = database
      .ref(`organizations/${orgId}/orgDetails`)
  return dbRef.get()
      .then((snapshot) => {
        const val = snapshot.val()
        const orgDetails = val
        return orgDetails
      })
      .catch((err) => {
        console.log('error: ', err)
      })
}
