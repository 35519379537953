/* eslint-disable max-len */
import React, {useEffect, useState} from 'react';

import {Avatar} from '@lazarusai/forms-ui-components';

export default function MultiselectPreview(props) {
  const [isUnselected, setIsUnselected] = useState(false)

  useEffect(() => {
    setIsUnselected(props.isUnselected)
  }, [])

  return (
    <div
      className={`cursor-pointer multiselect-preview ${isUnselected ? 'multiselect-unselected': ''}`}
      key={`multipre-${props.node.node_id}-${props.index}-${props.numberMultiselect}`}
      onClick={() => {
        setIsUnselected(!isUnselected)
        props.updateMultiUnselect(!isUnselected)
      }}
    >
      <div
        className='header'
      >
        <Avatar
          size={'medium'}
          color={2}
          style={{width: '2em'}}
          name=''
        />
        <span>{props.node.node_id}</span>
      </div>
      <div className='body'>
        {props.node.id}
      </div>
    </div>
  )
}

