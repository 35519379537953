import types from '../types'
import firebase from 'firebase/compat/app';


export const signOut = (clearStore=true) => (dispatch, getState) => {
  firebase.auth().signOut()
      .then(() => {
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            isSignedIn: false,
            isLoading: false,
            defaultAuthForm: {
              rikaiKey: '',
              orgId: '',
              authKey: '',
            },
            authForm: {
              rikaiKey: '',
              orgId: '',
              authKey: '',
            },
            defaultSignInForm: {
              username: '',
              password: '',
            },
            signInForm: {
              username: '',
              password: '',
            },
          },
        })
      })
      .then(() => {
        if (clearStore) {
          dispatch({type: 'CLEAR_STORE'})
        }
      })
      .then(() => dispatch({
        // needed to set loading false
        type: types.STORE_PAYLOAD,
        payload: {
          isSignedIn: false,
          isLoading: false,
        },
      }))
      .catch((error) => {
        console.log('Error on sign out', error)
      })
}
