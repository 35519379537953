import types from '../types'

export const getAuthKey = (database, orgId) => (dispatch, getState) => {
  const authForm = getState().userReducer.authForm
  const dbRef = database
      .ref(`organizations/${orgId}/activeApis/genericFormApi/authToken`)
  dbRef.get()
      .then((snapshot) => {
        const val = snapshot.val()
        if (val) {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              authForm: {
                ...authForm,
                authKey: val,
              },
            },
          })
        }
      })
      .catch((err) => {
        console.log('err: ', err)
      })
}
