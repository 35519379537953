/* eslint-disable max-len */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

import {removeDuplicates} from '../actions/removeDuplicates';
import {setSearchSize} from '../actions/setSearchSize';
import {exportGraph} from '../actions/exportGraph';

import {Button, Icon, Input} from '@lazarusai/forms-ui-components';
// import Helpers from '../Helpers';

import '../styles/Settings.css'
import '../styles/GraphSettings.css'

function GraphSettings(props) {
  const [editedSearchSize, setEditedSearchSize] = useState(null)

  useEffect(() => {
    if (props.searchSize) {
      setEditedSearchSize(props.searchSize)
    }
  }, [props.searchSize])

  return (
    <div className='graph-settings'>
      <h3>Global Graph Settings/Actions</h3>
      <div className='search-size-edit'>
        <Input
          onChange={(e) => {
            setEditedSearchSize(e.target.value)
          }}
          inputType='number'
          value={`${editedSearchSize}`}
          placeholder={props.searchSize + ' currently'}
          label='Search Size'
          info='Intended number of nodes to return on a search'
          theme={props.theme}
          key={'input-'+props.searchSize}
        />
        {(parseInt(editedSearchSize) !== props.searchSize && parseInt(editedSearchSize) > 0 && editedSearchSize?.length > 0) &&
          <Button
            text='Save Changes'
            icon={<Icon icon='save-outline' />}
            onClick={() => {
              props.setSearchSize(
                  props.database,
                  props.userId,
                  parseInt(editedSearchSize),
              )
            }}
            theme={props.theme}
            type='8'
          />
        }
      </div>
      { (props.graphList && Object.keys(props.graphList).length > 0) &&
        <div>
          <h3>Selected Graph Settings/Actions</h3>
          <div style={{marginBottom: '1em'}} className='card-container'>
            <h3 className='font-ocr'>Information</h3>
            <div className='section-block'>
              <div className='section-content'>Id: {props.selectedGraphId}</div>
              <div className='section-content'>Nodes: {props.graphList[props.selectedGraphId]?.nodes}</div>
              {props.graphList[props.selectedGraphId]?.metadata && <div
                className='section-content'
                style={{whiteSpace: 'pre-wrap'}}
              >
                Metadata: {'\n' + JSON.stringify(props.graphList[props.selectedGraphId]?.metadata, null, 2)}
              </div>}
            </div>
          </div>
          <h3>Export Graph</h3>
          <div style={{marginBottom: '1em'}} className='card-container'>
            <Button
              type={8}
              text={`Export ${props.selectedGraphId}`}
              onClick={() => {
                props.exportGraph(props.database)
              }}
              theme={props.theme}
              style={{marginBottom: '0em'}}
            />
          </div>
          {/* <div style={{marginBottom: '1em'}} className='card-container'>
            <h3 className='font-ocr'>Actions</h3>
            <div className='section-block'>
              <div className='section-content'>Remove Duplicated</div>
              <div className='section-follow-up'>
                Delete repeated nodes that may have been incorrectly injected into the graph.
                <Button
                  theme={'dark'}
                  type={10}
                  text='Remove Duplicates'
                  icon={
                    <Icon
                      icon={'trash-outline'}
                    />
                  }
                  onClick={(e) => {
                    if (!props.isLoading) {
                      e.preventDefault()
                      props.removeDuplicates(props.database)
                    }
                  }}
                  disabled={!(props.userData?.vkgAccess?.vkgs?.includes('write') || props.userData?.vkgAccess?.[Helpers.encodeVKGId(props.selectedGraphId)]?.includes('write'))}
                />
              </div>
            </div>
          </div> */}
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  selectedGraphId: state.userReducer.selectedGraphId,
  graphList: state.userReducer.graphList,
  isLoading: state.userReducer.isLoading,
  userId: state.userReducer.userId,
  searchSize: state.userReducer.searchSize,
  theme: state.userReducer.theme,
  userData: state.userReducer.userData,
})

export default connect(
    mapStateToProps,
    {removeDuplicates, setSearchSize, exportGraph},
)(GraphSettings)
