/* eslint-disable max-len */
import Helpers from '../Helpers'
import types from '../types'

export const getGraphs = (database) => (dispatch, getState) => {
  const userReducer = getState().userReducer
  const {vkgDomain, authForm, userId} = userReducer
  const {authKey, orgId, licenseKey} = authForm
  if (!vkgDomain) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        nodes: {},
        isLoading: false,
        userMessage: 'Your organization doesn\'t have VKG access.',
        notificationType: 2,
        notificationIcon: 'check',
        isNotificationVisible: true,
      },
    })
  } else if (!(userReducer.userData?.vkgAccess)) {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: false,
        userMessage: 'You don\'t have read VKG access.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      },
    })
  } else {
    dispatch({
      type: types.STORE_PAYLOAD,
      payload: {
        isLoading: true,
      },
    })
    const endpoint = vkgDomain + '/api/vkgs'
    const headers = {'userId': userId, 'authKey': authKey, 'orgId': orgId, 'Authorization': `Bearer ${licenseKey}`}
    Helpers.fetchGetJson(endpoint, headers, {})
        .then((res) => {
          if (!userReducer.userMessage && res.status !== 'SUCCESS') {
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                // eslint-disable-next-line max-len
                graphList: [],
                isLoading: false,
                userMessage: `Error: ${res.error || res.message}`,
                notificationType: 2,
                notificationIcon: 'warning',
                isNotificationVisible: true,
                isSignedIn: false,
              },
            })
          } else {
            const vkgs = res.vkgs ? res.vkgs : []
            dispatch({
              type: types.STORE_PAYLOAD,
              payload: {
                // eslint-disable-next-line max-len
                graphList: vkgs,
                isLoading: false,
              },
            })
          }
        })
        .catch((error) => {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              // eslint-disable-next-line max-len
              graphList: [],
              isLoading: false,
              userMessage: 'Failed to load in all graphs.',
              notificationType: 2,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            },
          })
        })
  }
}
