/* eslint-disable max-len */
import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'

import '../styles/ColorSelect.css'

import {storePayload} from '../actions/storePayload'

function ColorSelect(props) {
  const [areOptionsOpen, setAreOptionsOpen] = useState(false)

  const COLORS = ['#d12a54', '#1d80dd', '#feb72d', '#2db687', '#bd7241', '#46ddfd', '#848ffa', '#5d247c']

  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (document.getElementById(`color-select-${props.metadataValuesKey}-${props.value}`) && !document.getElementById(`color-select-${props.metadataValuesKey}-${props.value}`).contains(e.target)) {
        setAreOptionsOpen(false)
      }
    })
  }, [])

  return (
    <div className='color-select' id={`color-select-${props.metadataValuesKey}-${props.value}`}>
      <div
        className={`color-option ${!props.selectedColor ? 'color-option-empty': ''}`}
        onClick={() => {
          setAreOptionsOpen(!areOptionsOpen)
        }}
        style={{
          backgroundColor: props.selectedColor,
        }}
      >
      </div>
      {areOptionsOpen &&
        <div className='color-option-section'>
          <div className='color-header'>
            {`Available Colors: ${props.metadataValuesKey} - ${props.value}`}
          </div>
          <div className='color-body'>
            {COLORS.map((color) => {
              return (
                <div
                  key={`color-select-${color}`}
                  style={{
                    backgroundColor: color,
                  }}
                  className='color-option'
                  onClick={() => {
                    props.onColorClick(color)
                    setAreOptionsOpen(false)
                  }}
                >
                </div>
              )
            })}
            <div
              key={`color-select-null`}
              className='color-option color-option-empty'
              onClick={() => {
                props.onColorClick(null)
                setAreOptionsOpen(false)
              }}
            >
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
})

export default connect(
    mapStateToProps,
    {storePayload},
)(ColorSelect)
