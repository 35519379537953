import types from '../types'

export const setSearchSize = (database, userId, size) => (dispatch) => {
  const dbRef = database
      .ref(`users/${userId}/vkg/searchSize`)
  return dbRef.set(size)
      .then(() => {
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Search size saved.',
            notificationType: 2,
            notificationIcon: 'check',
            isNotificationVisible: true,
            searchSize: size,
          },
        })
      })
      .catch((err) => {
        console.log('error: ', err)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'Error saving search size.',
            notificationType: 2,
            notificationIcon: 'warning',
            isNotificationVisible: true,
          },
        })
      })
}
