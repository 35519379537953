/* eslint-disable max-len */
import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'

import {Table, TableEntry, Button, Icon} from '@lazarusai/forms-ui-components'

import {storePayload} from '../actions/storePayload'

import '../styles/PermissionSettings.css'

function PermissionSettings(props) {
  const [entries, setEntries] = useState([])

  useEffect(() => {
    setEntries(props.usersObj ?
      Object.keys(props.usersObj).sort((aId, bId) => {
        return (props.usersObj?.[aId]?.email || 'zzz').toLowerCase().localeCompare((props.usersObj?.[bId]?.email || 'zzz').toLowerCase())
      }).map((userId, i) => {
        const user = props.usersObj[userId]
        return (
          <TableEntry
            key={'user-' + i}
            content={[
              <span key='email' className='text-bright-blue'>
                {user['email']}
              </span>,
              <span key='date'>{user['datetimeCreated']}</span>,
              <Button
                key='role'
                theme={props.theme}
                className='role-button capitalize'
                type={8}
                text={getPermissionAccessString(user)}
                icon={<Icon icon='arrow-right-outline' />}
                iconPosition='right'
                iconJustify='edge'
                disabled={userId === props.user?.uid}
                onClick={() => {
                  props.storePayload({
                    permissionsID: userId,
                    permissionsEmail: user.email,
                    permissionsUser: user,
                    isPermissionsModalShowing: true,
                  })
                }}
                id={`${user['email']}-permissions-button`}
              ></Button>,
            ]}
            rowId={`${user['email']}-row`}
          />
        )
      }) :
      [])
  }, [props.usersObj, props.user])

  const currentUser = props.user && props.users && props.users.filter((user) => user.email === props.user.email)[0]

  function getAccessString(user) {
    if (user?.vkgAccess?.vkgs?.includes('read') || user?.vkgAccess?.vkgs?.includes('write')) {
      return 'Full Access'
    }
    return 'Limited Access'
  }

  function getPermissionAccessString(user) {
    if (user?.permissions?.writeVKG) {
      return 'Read/Write - ' + getAccessString(user)
    } else if (user?.permissions?.readVKG) {
      return 'Read - ' + getAccessString(user)
    } else if (user?.role === 'admin' && !user?.vkgAccess) {
      return 'Legacy Admin Access'
    } else {
      return 'No Access'
    }
  }

  return (
    <div className='tab-container permissions-section'>
      <Table
        theme={props.theme}
        title='Your VKG Permissions'
        className='user-table hide-user-table-scroll table-card-container'
        columnTitles={['Email', 'Role', 'Access']}
        showCount={false}
        entries={[
          <TableEntry
            key={'user'}
            content={[
              <span key='email' className='text-bright-blue'>
                {currentUser && currentUser.email }
              </span>,
              <span key='email' className='text-success-light capitalize'>
                {currentUser && currentUser.role}
              </span>,
              <span
                key='access'
                className={'text-success-light'}
              >
                {getPermissionAccessString(currentUser)}
              </span>,
            ]}
          />,
        ]}
        maxHeight='18rem'
      />
      <Table
        className='table-card-container'
        theme={props.theme}
        title='Organization VKG Permission'
        total={0}
        columnTitles={['Email', 'Created', 'Change Access']}
        entries={entries}
        tableId={'org-permissions-table'}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  usersObj: state.userReducer.usersObj,
  users: state.userReducer.users,
  userData: state.userReducer.userData,
  user: state.userReducer.user,
  theme: state.userReducer.theme,
  authForm: state.userReducer.authForm,
})

export default connect(
    mapStateToProps,
    {storePayload},
)(PermissionSettings)
