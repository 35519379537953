import types from '../types'

export const getSearchSize = (database, userId) => (dispatch) => {
  const dbRef = database
      .ref(`users/${userId}/vkg/searchSize`)
  return dbRef.get()
      .then((snapshot) => {
        if (!snapshot.exists()) {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              searchSize: 15,
            },
          })
        } else {
          const searchSize = snapshot.val()
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              searchSize: searchSize,
            },
          })
        }
      })
      .catch((err) => {
        console.log('error: ', err)
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: 'There was an error getting your saved search size.',
            notificationType: 2,
            notificationIcon: 'warning',
            isNotificationVisible: true,
            searchSize: 15,
          },
        })
      })
}
