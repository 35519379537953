/* eslint-disable max-len */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';

import {storePayload} from '../actions/storePayload'

import '../styles/MetadataTable.css'
import {Icon, Select, Switch} from '@lazarusai/forms-ui-components';

function MetadataTable(props) {
  const [sortUp, setSortUp] = useState(true)
  const [sortByKey, setSortByKey] = useState(true)
  const [metadata, setMetadata] = useState({})

  // TEXTAREA SIZING
  const minHeight = 48
  const maxHeight = 800

  useEffect(() => {
    if (props.metadataState) {
      setMetadata(props.metadataState)
    }
  }, [props.metadataState])

  function sortMetadata(keys) {
    keys.sort((aKey, bKey) => {
      const aVal = sortByKey ? aKey: metadata[aKey]
      const bVal = sortByKey ? bKey: metadata[bKey]
      if (aVal < bVal) {
        return 1
      } else if (aVal > bVal) {
        return -1
      } else {
        return 0
      }
    })
    return sortUp ? keys: keys.reverse()
  }

  function toggleSortDirection() {
    setSortUp(!sortUp)
  }

  function resizeTextarea(id) {
    const editEl = document.getElementById(id)
    if (editEl) {
      editEl.style.height = minHeight + 'px'
      editEl.style.height =
        (editEl.scrollHeight > maxHeight ? maxHeight : editEl.scrollHeight) +
        'px'
      editEl.scrollTop = editEl.scrollHeight
    }
  }

  function getInputType(value) {
    // if (typeof value === 'boolean') {
    //   return 'boolean'
    // } else
    if (typeof value === 'string' && (/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}/.test(value) || /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}/.test(value)) && value.length < 20 ) {
      return 'datetime'
    } else if (typeof value === 'number' || (!isNaN(parseFloat(value)) && /^-?\d+\.?\d*$/.test(value))) {
      return 'number'
    } else {
      return 'string'
    }
  }

  function getMetadataType(metadataKey) {
    const type = props.metadata?.[metadataKey]?.type
    const isOneOf = Object.keys(props.metadata?.[metadataKey] || {}).includes('oneOf')
    return isOneOf ? 'oneOf': type
  }

  function getInputComponent(metadataKey) {
    const type = getMetadataType(metadataKey)
    const newType = getInputType(metadata[metadataKey])
    const needWarning = newType !== type && type !== 'oneOf' && type !== 'any'
    const newMessage = `Metadata Issue: You changed ${metadataKey} from type ${type} to type ${newType}`
    if (metadata[metadataKey] !== null && needWarning && newMessage !== props.userMessage && !(props.userMessage || '').includes('Metadata Issue:')) {
      props.storePayload({
        userMessage: `Metadata Issue: You changed ${metadataKey} from type ${type} to type ${newType}`,
        notificationType: 1,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      })
    }
    if (type === 'oneOf') {
      return (
        <div
          className={`value select-div ${needWarning ? 'warning': ''} ${!props.canEdit ? 'disabled-cursor': ''}`}
        >
          <Select
            onClickOption={(optionName) => {
              metadata[metadataKey] = optionName
              setMetadata(JSON.parse(JSON.stringify(metadata)))
              props.onMetadataEdit(metadataKey, metadata[metadataKey])
            }}
            options={props.metadata[metadataKey]?.oneOf}
            value={metadata[metadataKey]}
            theme={props.theme}
            textMaxLength={30}
          />
        </div>
      )
    } else if (type === 'number') {
      return (
        <input
          className={`value ${needWarning ? 'warning': ''}`}
          type='number'
          value={metadata[metadataKey]}
          disabled={!props.canEdit}
          onChange={(e) => {
            if (!isNaN(parseFloat(e.target.value)) || !e.target.value.length) {
              metadata[metadataKey] = parseFloat(e.target.value) || e.target.value
              setMetadata(JSON.parse(JSON.stringify(metadata)))
              props.onMetadataEdit(metadataKey, metadata[metadataKey])
            } else {
              e.preventDefault()
            }
          }}
        ></input>
      )
    } else if (type === 'boolean') {
      return (
        <div
          className={`value ${needWarning ? 'warning': ''}`}
          type='number'
        >
          <Switch
            label={`${metadata[metadataKey]}`}
            checked={metadata[metadataKey]}
            onChange={(e) => {
              if (props.canEdit) {
                metadata[metadataKey] = e.target.checked
                setMetadata(JSON.parse(JSON.stringify(metadata)))
                props.onMetadataEdit(metadataKey, e.target.checked)
              }
            }}
            styles={{margin: '0px'}}
            theme={props.theme}
          />
        </div>
      )
    } else if (type === 'datetime') {
      return (
        <input
          className={`value ${needWarning ? 'warning': ''}`}
          type='datetime-local'
          value={metadata[metadataKey]}
          disabled={!props.canEdit}
          onChange={(e) => {
            metadata[metadataKey] = e.target.value
            setMetadata(JSON.parse(JSON.stringify(metadata)))
            props.onMetadataEdit(metadataKey, e.target.value)
          }}
        ></input>
      )
    } else { // stringf
      setTimeout(() => {
        resizeTextarea(`${metadataKey}-textarea`)
      }, [1])
      return (
        <textarea
          className={`value ${needWarning ? 'warning': ''}`}
          id={`${metadataKey}-textarea`}
          value={metadata[metadataKey]}
          disabled={!props.canEdit}
          onChange={(e) => {
            metadata[metadataKey] = e.target.value
            setMetadata(JSON.parse(JSON.stringify(metadata)))
            props.onMetadataEdit(metadataKey, e.target.value)
            resizeTextarea(`${metadataKey}-textarea`)
          }}
        ></textarea>
      )
    }
  }

  return (
    <div className={`metadata-table ${props.canEdit ? 'active': ''}`}>
      <div className={`header-row`}>
        <div
          className='key'
        >
          <span
            onClick={() => {
              setSortByKey(true)
              setSortUp(true)
            }}
          >
            Key
          </span>
          {sortByKey && <Icon
            icon={sortUp ? 'chevron-down-outline': 'chevron-up-outline'}
            key={sortUp ? 'chevron-down-outline': 'chevron-up-outline'}
            onClick={toggleSortDirection}
          />}
        </div>
        <div
          className='value'
        >
          <span
            onClick={() => {
              setSortByKey(false)
              setSortUp(true)
            }}
          >
            Value
          </span>
          {!sortByKey && <Icon
            icon={sortUp ? 'chevron-down-outline': 'chevron-up-outline'}
            key={sortUp ? 'chevron-down-outline': 'chevron-up-outline'}
            onClick={toggleSortDirection}
          />}
        </div>
      </div>
      {props.metadata && metadata && sortMetadata(Object.keys(metadata))
          .map((metadataKey, index) => {
            return (
              <div className='row' key={`${index}-${metadataKey}`}>
                <div className='key'>{metadataKey}</div>
                {getInputComponent(metadataKey)}
              </div>
            )
          })}
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  vkgDomain: state.userReducer.vkgDomain,
  authForm: state.userReducer.authForm,
  userId: state.userReducer.userId,
  theme: state.userReducer.theme,
  metadata: state.userReducer.metadata,
  userMessage: state.userReducer.userMessage,
})

export default connect(
    mapStateToProps,
    {storePayload},
)(MetadataTable)
