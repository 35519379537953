/* eslint-disable max-len */
import React from 'react'
import {connect} from 'react-redux'

import '../styles/KeyValueWrapper.css'
import {Icon} from '@lazarusai/forms-ui-components'

import {storePayload} from '../actions/storePayload'

function KeyValueWrapper(props) {
  return (
    <div
      className='key-value-wrapper'
    >
      <div
        className='key-value'
      >
        <span className='key'>{props.keyText}</span>
        {props.valueText &&
          <>
            <span className='key'>: </span>
            <span className='value'>{props.valueText}</span>
          </>
        }
      </div>
      <div
        className='key-value-close'
        onClick={props.onClose}
      >
        <Icon
          icon={'close-outline'}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
})

export default connect(
    mapStateToProps,
    {storePayload},
)(KeyValueWrapper)
