import {
  getAuth,
  getMultiFactorResolver,
  TotpMultiFactorGenerator,
} from 'firebase/auth'
import types from '../types'

// You need this to return a function, so thunk can handle it
export const enterMFA = (otp, error) => (dispatch) => {
  const auth = getAuth()
  const resolver = getMultiFactorResolver(auth, error)
  // hints[0] is the index for TOTP
  const multiFactorAssertion =
    TotpMultiFactorGenerator.assertionForSignIn(resolver.hints[0].uid, otp)
  // Finalize sign-in
  return resolver.resolveSignIn(multiFactorAssertion)
      .then((res) => {
        dispatch({
          type: types.STORE_PAYLOAD,
          payload: {
            userMessage: null,
            onMFATab: false,
            isSignedIn: true,
            isLoading: false,
            userId: auth.currentUser.uid || auth.currentUser,
          },
        })
      })
      .catch((error) => {
        console.log('error', error)
        if (error.code === 'auth/invalid-verification-code') {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              userMessage: 'Invalid code - please try again.',
              notificationType: 2,
              notificationIcon: 'warning',
              onMFATab: true,
              isLoading: false,
              isNotificationVisible: true,
            },
          })
        } else {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              userMessage: 'MFA Issue - Please try again.',
              notificationType: 2,
              notificationIcon: 'warning',
              onMFATab: true,
              isLoading: false,
              isNotificationVisible: true,
            },
          })
        }
      })
}
