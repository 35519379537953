/* eslint-disable max-len */
import React, {useState, useRef, useEffect} from 'react'
import {connect} from 'react-redux'

import {storePayload} from '../actions/storePayload'

import Helpers from '../Helpers'

import '../styles/Modal.css'

import {getNodes} from '../actions/getNodes'
import {createGraph} from '../actions/createGraph'
import {uploadNodes} from '../actions/uploadNodes'
import {deleteNodes} from '../actions/deleteNodes'
import GraphCard from './GraphCard'
import NodeResultCard from './NodeResultCard'
import {Button, Icon, Input, Modal2 as ModalComp, SearchBar} from '@lazarusai/forms-ui-components'

import Upload from '../images/upload-outline.svg'

/**
 * There are three modals that are outlined in this code
 *
 * Upload New Nodes (modalMode = add)
 * - add nodes to current existing vkg
 *
 * Create New VKGs with Node (modalMode = add_new)
 * - add nodes to new vkg
 *
 * Menu (modalMode = menu)
 * - all current vkgs are displayed
 */

function Modal(props) {
  const [fileDrag, setFileDrag] = useState(false)
  const inputRef = useRef()

  const [fileForm, _setFileForm] = useState(null)
  const fileFormRef = useRef(null)

  function setFileForm(file) {
    fileFormRef.current = file
    _setFileForm(file)
  }

  const [fileContent, _setFileContent] = useState(null)
  const fileContentRef = useRef(null)

  const [metadataObj, setMetadataObj] = useState({})

  function setFileContent(content) {
    fileContentRef.current = content
    _setFileContent(content)
  }

  const [vkgName, _setVKGName] = useState('')
  // const vkgNameRef = useRef(null)

  function setVKGName(val) {
    // vkgNameRef.current = val
    _setVKGName(val)
  }

  const [isRemoveConfirmShowing, setIsRemoveConfirmShowing] = useState(false)
  const [listScrollPosition, setListScrollPosition] = useState(0)

  const [searchString, setSearchString] = useState('')

  useEffect(() => {
    if (props.isModalShowing && (props.multiselectNodes?.length || props.searchNodes?.length)) {
      const listEl = document.getElementById('menu-list')
      if (listEl) {
        listEl.scrollTop = listScrollPosition
      }
    }
  }, [props.multiselectNodes, props.searchNodes])

  async function closeModal(e) {
    if (props.modalMode !== 'delete' && document.getElementById(props.modalMode)) {
      document.getElementById(props.modalMode).getElementsByTagName('textarea')[0].value = ''
    }
    e.preventDefault()
    props.storePayload({
      isModalShowing: false,
      unselectedModalNodes: [],
    })
    setIsRemoveConfirmShowing(false)
  }

  async function openNewVKGModal(e) {
    e.preventDefault()
    props.storePayload({
      isModalShowing: true,
      modalMode: 'add_new',
    })
  }

  function updateNodesPositions(posToRemove, searchNodes) {
    const newSearchNodes = [...searchNodes]
    for (let i = 0; i < posToRemove.length; i++) {
      for (let j = 0; j < searchNodes.length; j++) {
        if (posToRemove[i] < searchNodes[j].pos) {
          newSearchNodes[j].pos = newSearchNodes[j].pos - 1
        }
      }
    }
    return newSearchNodes
  }

  async function onChangeFile(e) {
    props.storePayload({
      isLoading: true,
    })
    const file = await e.target.files.length ?
                    e.target.files[0] :
                    e.dataTransfer?.files?.length ?
                      e.dataTransfer.files[0] :
                      null
    if (!file) {
      props.storePayload({
        isLoading: false,
      })
      return
    }
    // const files = await e.target.files.length ?
    //                 e.target.files :
    //                 e.dataTransfer.files.length ?
    //                   e.dataTransfer.files :
    //                   null
    setMetadataObj({})
    if (file.type === 'text/csv') {
      const reader = new FileReader();
      reader.onload = function(e) {
        const rawContent = e.target.result
        const title_line = rawContent.substring(0, rawContent.indexOf('\n')).replace('\r', '')
        const colHeaders = title_line.split(',')
        const title_cell = colHeaders[0]
        colHeaders.shift()
        const metadataTitles = colHeaders.map((key) => {
          const returnObj = {}
          returnObj[key] = {'default': null, 'type': 'any'}
          return returnObj
        })
        const failedKeys = Object.keys(metadataTitles).map((key) => isMetadataKeyValid(Object.keys(metadataTitles[key])[0]) ? null : Object.keys(metadataTitles[key])[0]).filter((key) => key)
        if (failedKeys.length) {
          setMetadataObj(null)
          setFileForm(null)
          setFileContent(null)
          props.storePayload({
            userMessage: `Metadata keys must be lowercase alphanumeric with underscores and cannot be any of the following: ${['text', 'vector', 'metadata', 'last_tsne', 'tsne'].join(', ')}. The following metadata keys failed: ${failedKeys.join(', ')}`,
            notificationType: 2,
            notificationIcon: 'warning',
            isNotificationVisible: true,
            isLoading: false,
          })
        } else {
          setMetadataObj(JSON.stringify(Object.assign({}, ...metadataTitles), null, 2))
          if (title_cell === 'text' || title_cell === 'text\r') {
            // the header matches
            // dont send all the lines for large files
            const lines = rawContent.split('\n')
            const previewCutoff = lines.length > 100 ? 100 : lines.length
            const previewLines = lines.slice(1, previewCutoff)
            if (lines.length > 100) {
              previewLines.push('...')
            }
            setFileForm(file)
            setFileContent(previewLines)
            props.storePayload({
              isLoading: false,
            })
          } else {
            props.storePayload({
              userMessage: 'CSV uploads must start with a column with the header "text"',
              notificationType: 2,
              notificationIcon: 'warning',
              isNotificationVisible: true,
              isLoading: false,
            })
            setFileForm(null)
          }
        }
      }
      reader.readAsText(file)
    } else if (file.type === 'application/json') {
      const reader = new FileReader();
      reader.onload = function(e) {
        const rawContent = e.target.result
        const jsonContent = JSON.parse(rawContent)
        const isValidJson = Array.isArray(jsonContent) && jsonContent.map((node) => Object.keys(node).includes('text')).every((val) => val)
        if (isValidJson) {
          // the header matches
          // dont send all the lines for large files
          const lines = jsonContent.map((node) => JSON.stringify(node))
          const previewCutoff = lines.length > 100 ? 100 : lines.length
          const previewLines = lines.slice(0, previewCutoff)
          if (lines.length > 100) {
            previewLines.push('...')
          }
          const allMetadataInter = [].concat(...jsonContent.map((node) => Object.keys(node?.['metadata'] || {})))
          const allMetadata = allMetadataInter.filter((nKey, nKeyIndex) => nKeyIndex === allMetadataInter.indexOf(nKey)).map((key) => {
            const returnObj = {}
            returnObj[key] = {'default': null, 'type': 'any'}
            return returnObj
          })
          const failedKeys = Object.keys(allMetadata).map((key) => isMetadataKeyValid(Object.keys(allMetadata[key])[0]) ? null : Object.keys(allMetadata[key])[0]).filter((key) => key)
          if (failedKeys.length) {
            setMetadataObj(null)
            setFileForm(null)
            setFileContent(null)
            props.storePayload({
              userMessage: `Metadata keys must be lowercase alphanumeric with underscores and cannot be any of the following: ${['text', 'vector', 'metadata', 'last_tsne', 'tsne'].join(', ')}. The following metadata keys failed: ${failedKeys.join(', ')}`,
              notificationType: 2,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            })
          } else {
            setMetadataObj(JSON.stringify(Object.assign({}, ...allMetadata), null, 2))
            setFileForm(file)
            setFileContent(previewLines)
          }
          props.storePayload({
            isLoading: false,
          })
        } else {
          props.storePayload({
            userMessage: `json uploads must follow this format (metadata optional depending on VKG): ${JSON.stringify([{'text': 'node 1', 'metadata': {'metadataKey1': 'metadataValue1', 'metdataKey2': 'metadataValue2'}}, {'text': 'node 2', 'metadata': {'metadataKey1': 'metadataValue3', 'metdataKey2': 'metadataValue4'}}, '...'])}`,
            notificationType: 2,
            notificationIcon: 'warning',
            isNotificationVisible: true,
            isLoading: false,
          })
          setFileForm(null)
        }
      }
      reader.readAsText(file)
    } else {
      props.storePayload({
        userMessage: 'Only CSVs and JSON files are supported.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
        isLoading: false,
      })
    }
  }

  function isVKGNameValid(name) {
    // SHOULD REFLECT THE RULES ON THE BE
    const blackListNames = [
      '',
      'vkg',
      'vkgs',
      'id',
      'ids',
      'node',
      'nodes',
      'query',
      'queries',
      'hash',
      'hashes',
      'last_tsne',
      'tsne',
      'metadata',
      'stat',
      'stats',
      'lock',
    ]
    return !(blackListNames.includes(name) || /[`!#$%^&*+=;:\\.<>\/?~]/.test(name))
  }

  function isMetadataKeyValid(key) {
    const blackListKeys = [
      'text', 'vector', 'metadata', 'last_tsne', 'tsne',
    ]
    return !(blackListKeys.includes(key) || !/^[0-9a-z_]+$/.test(key))
  }

  function dragOver(e) {
    e.preventDefault();
    setFileDrag(true);
  }

  function dragEnter(e) {
    e.preventDefault();
    setFileDrag(true);
  }

  function dragDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setFileDrag(false);
    onChangeFile(e)
  }

  function dragLeave(e) {
    e.preventDefault()
    setFileDrag(false)
  }

  function onGraphCardClick(e, vkg_id) {
    e.preventDefault()
    props.storePayload({
      selectedGraphId: vkg_id,
      isFilterSearch: false,
      visualizations: null,
      activeFilters: null,
      metadata: null,
      metadataValues: null,
    })
    Helpers.historyPush(`?vkgId=${vkg_id}`)
    props.getNodes(props.database, vkg_id, false)
    props.resetGraphView()
  }

  async function confirmDelete(e) {
    const confirmResp = confirm('By clicking "OK" you are confirming that the selected nodes should be deleted')
    if (confirmResp) {
      setIsRemoveConfirmShowing(!isRemoveConfirmShowing)
      const baseList = props.modalMode.includes('search') ? props.searchNodes : props.multiselectNodes
      const nodeIds = baseList.map((node) => {
        return node.node_id
      }).filter((id) => {
        return !props.unselectedModalNodes.includes(id)
      })
      // nodeIds - ids to remove / selected
      props.removeMultiselectFromSearch(baseList
          .map((node) => {
            return !props.unselectedModalNodes.includes(node.node_id) ? node.pos : false
          })
          .filter((val) => {
            return val
          }))
      const deleteSuccess = props.deleteNodes(nodeIds)
      if (deleteSuccess) {
        let filteredSearch = []
        if (baseList) {
          filteredSearch = baseList.filter((nodeObj) => !nodeIds.includes(nodeObj.node_id))
        }
        closeModal(e)
        if (props.modalMode.includes('search')) {
          // search result modal
          let newSearchNodes = []
          if (props.searchNodes) {
            newSearchNodes = await updateNodesPositions(
                props.searchNodes.filter((node) => nodeIds.includes(node.node_id)).map((node) => node.pos), // positions of removed nodes
                filteredSearch)
          }
          if (newSearchNodes.length === 0) {
            props.clearSearch(e)
          } else {
            props.storePayload({searchNodes: newSearchNodes})
          }
        } else {
          // multi select modal
          let newSearchNodes = props.searchNodes ? props.searchNodes : []
          if (props.searchNodes) {
            newSearchNodes = await updateNodesPositions(
                baseList.filter((node) => nodeIds.includes(node.node_id)).map((node) => node.pos), // positions of removed nodes
                props.searchNodes.filter((nodeObj) => !nodeIds.includes(nodeObj.node_id)))
          }
          let newMultiNodes = props.multiselectNodes ? props.multiselectNodes : []
          if (props.multiselectNodes) {
            newMultiNodes = await updateNodesPositions(
                baseList.filter((node) => nodeIds.includes(node.node_id)).map((node) => node.pos), // positions of removed nodes
                props.multiselectNodes.filter((nodeObj) => !nodeIds.includes(nodeObj.node_id)))
          }
          if (props.searchNodes?.length && newSearchNodes.length === 0) {
            props.clearSearch(e)
          } else {
            props.storePayload({
              searchNodes: newSearchNodes,
            })
          }
          if (props.multiselectNodes?.length && newMultiNodes.length === 0) {
            props.clearMultiselect(e)
          } else {
            props.storePayload({
              multiselectNodes: newMultiNodes,
            })
          }
        }
      }
    } else {
      setIsRemoveConfirmShowing(false)
    }
  }

  const helpText = (<>
    Mass upload should be structured as a .csv file with {'"text"'} as the first column header or as a .json
    file with structured as follows {`(metadata optional)`} {JSON.stringify([{'text': 'node 1', 'metadata': {'metadataKey1': 'metadataValue1', 'metdataKey2': 'metadataValue2'}}, {'text': 'node 2', 'metadata': {'metadataKey1': 'metadataValue3', 'metdataKey2': 'metadataValue4'}}, '...'])}.
  </>)

  function downloadResults() {
    const res = props.modalMode.includes('search') ? props.searchNodes : props.multiselectNodes
    const results = res.filter((node) => {
      return !props.unselectedModalNodes.includes(node.node_id)
    })
    const sampleResult = results[0]
    const originalHeaders = Object.keys(sampleResult)
    const headersToKeep = ['id', 'pos', 'x', 'y', 'z', 'node_id', 'metadata']
    const rows = []
    const headers = originalHeaders.filter((val) => headersToKeep.includes(val))
    for (let i = 0; i < results.length; i++) {
      const valueRow = []
      for (let j = 0; j < headers.length; j++) {
        valueRow.push(`${results[i][headers[j]] ?
          Helpers.processCSVCell(JSON.stringify(results[i][headers[j]])) :
          '{}'}`)
      }
      rows.push(valueRow.join(','))
    }
    headers[headers.indexOf('id')] = 'value'
    rows.unshift(headers.map((val) => `${Helpers.processCSVCell(val)}`).join(','))
    const data = rows.join('\n').replaceAll('\r', '')

    // Download Data
    const blob = new Blob([data], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('href', url)
    const date = new Date()
    let file_name = `${props.selectedGraphId}_search:${props.searchString}_${date.getMonth()+1}-${date.getDate()}-${date.getFullYear()}_${date.getTime()}.csv`
    if (props.modalMode.includes('multi')) {
      file_name = `${props.selectedGraphId}_multiselect_${date.getMonth()+1}-${date.getDate()}-${date.getFullYear()}_${date.getTime()}.csv`
    }
    a.setAttribute('download', file_name);
    a.click()
  }

  function getSortValue(name) {
    const temp = name.toLowerCase().indexOf(searchString.toLowerCase())
    return temp > -1 ? temp : 1000
  }

  return (
    <>
      {props.isModal &&
        <>
          <ModalComp
            isVisible={props.isModalShowing}
            style={{
              height: 'min(100vh, 800px)',
              width: 'min(100vw, 1000px)',
            }}
            theme={'dark'}
            onDismiss={(e) => {
              closeModal(e)
              setFileContent(null)
              setFileForm(null)
            }}
            title={
              (props.modalMode === 'add') ?
              'Add New Data' :
              (props.modalMode === 'add_new') ?
              'Create New Graph' :
              (props.modalMode === 'menu') ?
              'Vector Knowledge Graphs' :
              (props.modalMode.includes('node-')) ?
              (props.modalMode.includes('search') ?
                `Search (${props.searchNodes.length - props.unselectedModalNodes.length}/${props.searchNodes.length})`:
                `Multiselect (${props.multiselectNodes.length - props.unselectedModalNodes.length}/${props.multiselectNodes.length})`) :
              'Modal'
            }
            outsideClickDismisses={props.modalMode !== 'menu'}
            contentContainerStyle={{position: 'relative', width: '100%', overflow: 'hidden'}}
            contentStyle={{height: '100%', width: '100%', boxSizing: 'border-box', overflow: 'auto'}}
            footer={props.modalMode.includes('node-') ?
              <div className='result-btns'>
                <Button
                  type={10}
                  text={`Remove Results (${(props.modalMode.includes('search') ? props.searchNodes.length : props.multiselectNodes.length) - props.unselectedModalNodes.length})`}
                  icon={<Icon icon='trash-outline'/>}
                  iconPosition={'right'}
                  iconJustify={'edge'}
                  theme={'dark'}
                  width={'calc(100%)'}
                  onClick={confirmDelete}
                  disabled={!(props.userData?.vkgAccess?.vkgs?.includes('write') || props.userData?.vkgAccess?.[Helpers.encodeVKGId(props.selectedGraphId)]?.includes('write'))}
                />
                <Button
                  type={1}
                  text={`Download Results (${(props.modalMode.includes('search') ? props.searchNodes.length : props.multiselectNodes.length) - props.unselectedModalNodes.length})`}
                  download={true}
                  icon={<Icon icon='download-outline'/>}
                  iconPosition={'right'}
                  iconJustify={'edge'}
                  theme={'dark'}
                  width={'calc(100%)'}
                  onClick={(e) => {
                    downloadResults()
                  }}
                />
              </div> : <></>
            }
            // bodyStyle={{gridTemplateRows: 'max-content'}}
            closeId={'modal-close'}
          >
            <div className={`${props.modalMode === 'menu' ? 'graph-modal-content menu-content' : 'graph-modal-content'}`}>
              {(props.modalMode === 'add') ?
                  <>
                    {(fileForm === null) ?
                      <>
                        <div className='header-text'>
                          <p className='help-text'>
                            {helpText}
                          </p>
                        </div>
                        <div
                          className='graph-upload-location'
                        >
                          <input
                            className={fileDrag ? 'file-drag-over' : ''}
                            ref={inputRef}
                            name='file'
                            type='file'
                            title=' '
                            id='file-input'
                            accept='.csv, .json'
                            onChange={onChangeFile}
                            onDragEnter={dragEnter}
                            onDragOver={dragOver}
                            onDrop={dragDrop}
                            onDragLeave={dragLeave}
                          />
                          <img
                            src={Upload}
                            alt=''
                            className='graph-upload-icon'
                          />
                          <p>Choose a document file or drag it here</p>
                        </div>
                      </> :
                      <>
                        <p>{fileFormRef.current.name}</p>
                        <table>
                          <tbody>
                            {fileContent && fileContentRef.current.map((line, index) => {
                              return (
                                <tr key={`line-${index}`} id={`line-${index}`}>
                                  <td>
                                    {line}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                        <Button
                          type={1}
                          text={'Inject data to VKG'}
                          icon={<Icon icon='arrow-forward'/>}
                          iconPosition={'right'}
                          iconJustify={'edge'}
                          theme={'dark'}
                          style={{margin: '0em', position: 'absolute', bottom: '0px'}}
                          width={'calc(100%)'}
                          onClick={(e) => {
                            props.uploadNodes(props.database, props.uploadGraphId, fileFormRef.current)
                            setFileContent(null)
                            setFileForm(null)
                            closeModal(e)
                          }}
                          disabled={!(props.userData?.vkgAccess?.vkgs?.includes('write') || props.userData?.vkgAccess?.[Helpers.encodeVKGId(props.selectedGraphId)]?.includes('write'))}
                        />
                      </>
                    }
                  </> :
                  (props.modalMode === 'add_new') ?
                  <>
                    {(fileForm === null) ?
                      <>
                        <div className='header-text'>
                          <p className='help-text'>
                            {helpText}
                          </p>
                        </div>
                        <div
                          className='graph-upload-location'
                        >
                          <input
                            className={fileDrag ? 'file-drag-over' : ''}
                            ref={inputRef}
                            name='file'
                            type='file'
                            title=' '
                            id='file-input'
                            accept='.csv, .json'
                            onChange={onChangeFile}
                            onDragEnter={dragEnter}
                            onDrop={dragDrop}
                            onDragLeave={dragLeave}
                          />
                          <img
                            src={Upload}
                            alt=''
                            className='graph-upload-icon'
                          />
                          <p>Choose a document file or drag it here</p>
                        </div>
                      </> :
                      <>
                        {/* <p>{vkgName} {fileFormRef.current.name}</p> */}
                        <Input
                          label={'VKG Name'}
                          theme={'dark'}
                          onChange={(e) => {
                            setVKGName(e.target.value)
                          }}
                          value={vkgName}
                        />
                        <Input
                          label={'VKG Metadata'}
                          theme={'dark'}
                          type={2}
                          onChange={(e) => {
                            setMetadataObj(e.target.value)
                          }}
                          value={metadataObj}
                          info={'Metadata types can only be "number", "datetime", "string" or "any" (filtered search does not work for type "any").'}
                        />
                        <table>
                          <tbody>
                            {fileContent && fileContentRef.current.map((line, index) => {
                              return (
                                <tr key={`line-${index}`} id={`line-${index}`}>
                                  <td>
                                    {line}
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                        <Button
                          type={1}
                          text={'Create new VKG with data.'}
                          icon={<Icon icon='arrow-forward'/>}
                          iconPosition={'right'}
                          iconJustify={'edge'}
                          theme={'dark'}
                          style={{position: 'absolute', bottom: 'calc(0px)', marginBottom: '0px'}}
                          width={'calc(100%)'}
                          onClick={(e) => {
                            if (vkgName === '') {
                              props.storePayload({
                                userMessage: 'Give the VKG a name.',
                                notificationType: 2,
                                notificationIcon: 'warning',
                                isNotificationVisible: true,
                              })
                            } else if (!isVKGNameValid(vkgName)) {
                              props.storePayload({
                                userMessage: 'You cannot name a VKG a reserved name or using prohibited characters (`!#$%^&*+-=;:\\.<>\/?~)',
                                notificationType: 2,
                                notificationIcon: 'warning',
                                isNotificationVisible: true,
                              })
                            } else if (Object.keys(props.graphList).includes(vkgName)) {
                              props.storePayload({
                                userMessage: `${vkgName} already exists. Duplicate names are not allowed.`,
                                notificationType: 2,
                                notificationIcon: 'warning',
                                isNotificationVisible: true,
                              })
                            } else if (!Helpers.checkJsonStructure(metadataObj)) {
                              props.storePayload({
                                userMessage: `Metadata must be valid JSON`,
                                notificationType: 2,
                                notificationIcon: 'warning',
                                isNotificationVisible: true,
                              })
                            } else if (!Helpers.checkMetadataTypes(metadataObj)) {
                              props.storePayload({
                                userMessage: `Metadata type must be one of the follow: string, number, datetime, any`,
                                notificationType: 2,
                                notificationIcon: 'warning',
                                isNotificationVisible: true,
                              })
                            } else {
                              props.createGraph(props.database, vkgName, fileFormRef.current, JSON.parse(metadataObj))
                              setFileContent(null)
                              setFileForm(null)
                              setVKGName('')
                              closeModal(e)
                            }
                          }}
                          disabled={!(props.userData?.vkgAccess?.vkgs?.includes('create'))}
                        />
                      </>
                    }
                  </> :
                  (props.modalMode === 'menu') ?
                  <>
                    <div className='graph-menu-list graph-list-scroll-bar' id='graph-menu-list'>
                      <div className='search-bar-wrapper'>
                        <SearchBar
                          isCollapsed={false}
                          isCollapsible={false}
                          onChange={(e) => {
                            setSearchString(e.target.value)
                            document.getElementById('graph-menu-list').scrollTo({top: 0})
                          }}
                          autocomplete='off'
                          theme='dark'
                          inputId={'menu-search-input'}
                        />
                      </div>
                      {Object.keys(props.graphList).sort().sort((aKey, bKey) => {
                        const aVal = getSortValue(aKey)
                        const bVal = getSortValue(bKey)
                        return searchString.length ? aVal - bVal : 0
                      })
                          .map((vkg, i) => {
                            return (
                              <GraphCard
                                name={vkg}
                                nodes={props.graphList[vkg].nodes}
                                key={i}
                                graphId={vkg}
                                isHighlighted={vkg === props.selectedGraphId}
                                onClick={(e) => {
                                  onGraphCardClick(e, vkg)
                                  closeModal(e)
                                }}
                                closeModal={(e) => {
                                  closeModal(e)
                                }}
                              />
                            )
                          })}
                    </div>
                    <Button
                      type={1}
                      text={'Create new VKG'}
                      icon={<Icon icon='arrow-forward'/>}
                      iconPosition={'right'}
                      iconJustify={'edge'}
                      theme={'dark'}
                      onClick={(e) => {
                        setFileContent(null)
                        setFileForm(null)
                        setVKGName('')
                        openNewVKGModal(e)
                      }}
                      disabled={!(props.userData?.vkgAccess?.vkgs?.includes('create'))}
                      id='create-vkg-button'
                    />
                  </> :
                  (props.modalMode.includes('node-')) ?
                  <>
                    <div className='graph-menu-list graph-list-scroll-bar result-list-changes' id='menu-list' key={`menu-list`}>
                      {props.modalMode.includes('search') ?
                        props.searchNodes.map((node, i) => {
                          return (
                            <NodeResultCard
                              data={node.id}
                              coordinates={[Helpers.roundHundredths(node.x), Helpers.roundHundredths(node.y), Helpers.roundHundredths(node.z)]}
                              nodeId={node.node_id}
                              key={`result-${i}-${node.node_id}`}
                              onClickUnselect={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                const newSearch = [...props.searchNodes]
                                newSearch.splice(i, 1)
                                const listEl = document.getElementById('menu-list')
                                setListScrollPosition(listEl.scrollTop)
                                props.storePayload({searchNodes: newSearch})
                              }}
                            />
                          )
                        }) :
                        props.multiselectNodes.map((node, i) => {
                          return (
                            <NodeResultCard
                              data={node.id}
                              coordinates={[Helpers.roundHundredths(node.x), Helpers.roundHundredths(node.y), Helpers.roundHundredths(node.z)]}
                              nodeId={node.node_id}
                              key={`result-${i}-${node.node_id}`}
                              onClickUnselect={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                const newMulti = [...props.multiselectNodes]
                                newMulti.splice(i, 1)
                                const listEl = document.getElementById('menu-list')
                                setListScrollPosition(listEl.scrollTop)
                                props.storePayload({multiselectNodes: newMulti})
                              }}
                            />
                          )
                        })
                      }
                    </div>
                  </> :
                  <>
                  </>
              }
            </div>
          </ModalComp>
        </>
      }
    </>
  )
}

const mapStateToProps = (state, ownProps) => ({
  database: state.firebaseReducer.database,
  isModal: state.userReducer.isModal,
  isModalShowing: state.userReducer.isModalShowing,
  modalMode: state.userReducer.modalMode,
  updateValue: state.userReducer.updateValue,
  selectedGraphId: state.userReducer.selectedGraphId,
  graphList: state.userReducer.graphList,
  searchNodes: state.userReducer.searchNodes,
  searchString: state.userReducer.searchString,
  multiselectNodes: state.userReducer.multiselectNodes,
  unselectedModalNodes: state.userReducer.unselectedModalNodes,
  uploadGraphId: state.userReducer.uploadGraphId,
  userData: state.userReducer.userData,
})

export default connect(
    mapStateToProps, {storePayload, getNodes, createGraph, uploadNodes, deleteNodes},
)(Modal)
