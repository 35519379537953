/* eslint-disable max-len */
import Helpers from '../Helpers'

export const getInitialFilterState = (metadata) => {
  const metadataKeys = Object.keys(metadata)
  const activeFilters = {}
  for (let i = 0; i < metadataKeys.length; i++) {
    const metaType = Helpers.getInputType(metadata[metadataKeys[i]][0])
    if (metaType === 'boolean') {
      // activeFilters[metadataKeys[i]] = [true, false]
      activeFilters[metadataKeys[i]] = []
    } else if (metaType === 'number' || metaType === 'date') {
      activeFilters[metadataKeys[i]] = {
        'range': {
          // 'from': metadata[metadataKeys[i]][0],
          // 'to': metadata[metadataKeys[i]][metadata[metadataKeys[i]].length - 1],
        },
      }
    } else {
      activeFilters[metadataKeys[i]] = []
    }
  }
  return activeFilters
}
