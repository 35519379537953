import types from '../types'

export const getCustomDomain = (database, orgId) => (dispatch) => {
  const dbRef = database
      .ref(`organizations/${orgId}/vkgDomain`)
  dbRef.get()
      .then((snapshot) => {
        const val = snapshot.val()
        if (val) {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              vkgDomain: val,
              isSignedIn: val === null ? false : true,
            },
          })
        } else {
          dispatch({
            type: types.STORE_PAYLOAD,
            payload: {
              vkgDomain: null,
              isSignedIn: false,
              userMessage: 'Your organization doesn\'t have VKG access.',
              notificationType: 3,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            },
          })
        }
      })
      .catch((err) => {
        console.log('err: ', err)
      })
}
