import 'firebase/compat/auth'
import 'firebase/compat/database'

export const getUserData = (database, orgId, uid) => {
  const dbRef = database.ref(`organizations/${orgId}/userRoster/${uid}`)
  return dbRef.get().then((snapshot) => {
    const val = snapshot.val()
    const userData = val
    return userData
  })
}
