/* eslint-disable max-len */
// Dependencies'
import React, {useState, useEffect, Fragment} from 'react'
import {connect} from 'react-redux'
import firebase from 'firebase/compat/app'
// import {Route} from 'react-router-dom'
import Helpers from '../Helpers.js'

// Actions
import {createFirebaseApp} from '../actions/createFirebaseApp.js'
import {storePayload} from '../actions/storePayload.js'
import {signIn} from '../actions/signIn.js'
import {signOut} from '../actions/signOut.js'
import {onSignInSuccess} from '../actions/onSignInSuccess.js'
import {getCustomDomain} from '../actions/getCustomDomain.js'
import {enterMFA} from '../actions/enterMFA.js'


// Styles
import '@lazarusai/forms-ui-components/dist/index.css'
import {Notification, Input, Button, Loader, Icon} from '@lazarusai/forms-ui-components'
import '../styles/App.css'
import '../styles/Modal.css'
import Graph from './Graph.jsx'

import Check from '../images/check.svg'
import Warning from '../images/warning.svg'
import LazarusLogo from '../images/lazarus-logo.svg'

/**
 * VectorKnowledgeGraph
 * This file is the equivalent of App.js, but the name is changed so that
 * the imports don't get confusing.
 *
 * The main purpose of this file is to handle the authorization and conditionally
 * give access to the VKG to signed in users.
 *
 * The styles for the auth modal are in Modal.css
 */

function VectorKnowledgeGraph(props) {
  const [MFACode, setMFACode] = useState('')

  /* animates notification */
  const onClickNotification = () => {
    setTimeout(() => {
      props.storePayload({
        userMessage: null,
        notificationType: null,
        notificationIcon: null,
        isNotificationVisible: false,
      })
    }, 300)
  }

  useEffect(() => {
    props.createFirebaseApp()
  }, [])

  useEffect(() => {
    if (Object.keys(props.firestore).length) {
      listenForAuthChange()
    }
  }, [props.firestore])

  function listenForAuthChange() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        /* User is signed in, see docs for a list of available properties
           https://firebase.google.com/docs/reference/js/firebase.User
        */
        // const uid = user.uid
        /* Call relevant action to fetch user data */
        props.storePayload({userId: user.uid})
        props.onSignInSuccess(user, props.database)
      } else {
        /* User is not signed in */
        props.storePayload({
          isSignedIn: false,
          hasAttemptedSignIn: true,
          isLoading: false,
        })
      }
    })
  }

  /* Automatically close type 2 notifications */
  useEffect(() => {
    if (props.notificationType === 2) {
      setTimeout(onClickNotification, 10000)
    }
  }, [props.notificationType])

  function onClickSubmit(e) {
    e.preventDefault()
    props.signIn(signInForm.username, signInForm.password, true, props.database)
  }

  function onChangeSignInForm(e) {
    const newSignInForm = {
      ...signInForm,
      [e.target.name]: e.target.value,
    }
    props.storePayload({signInForm: newSignInForm})
  }

  function signOutClick(e) {
    e.preventDefault()
    props.signOut(props.orgId)
    setTab('signIn')
  }

  const {signInForm} = props

  function onClickForgotPassword(e) {
    e.stopPropagation()
    if (props.signInForm['username']) {
      firebase
          .auth()
          .sendPasswordResetEmail(props.signInForm['username'])
          .then((res) => {
            props.storePayload({
              userMessage: 'Please check your email to change your password.',
              notificationType: 2,
              notificationIcon: 'check',
              isNotificationVisible: true,
            })
          })
          .catch((err) => {
            props.storePayload({
              userMessage: 'Email not found. Please try again.',
              notificationType: 2,
              notificationIcon: 'warning',
              isNotificationVisible: true,
            })
          })
    } else {
      props.storePayload({
        userMessage: 'Please include your email.',
        notificationType: 2,
        notificationIcon: 'warning',
        isNotificationVisible: true,
      })
    }
  }

  const signInFormRender = (
    <>
      <form
        onChange={onChangeSignInForm}
        autoComplete='off'
      >
        <Input
          name='username'
          id='username'
          type={1}
          theme="dark"
          label="Email"
          value={props.signInForm['username']}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && props.signInForm['username'] && props.signInForm['password']) {
              onClickSubmit(e)
            }
          }}
        />
        <Input
          name='password'
          id='password'
          type={1}
          inputType='password'
          theme="dark"
          label="Password"
          value={props.signInForm['password']}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && props.signInForm['username'] && props.signInForm['password']) {
              onClickSubmit(e)
            }
          }}
        />
        <Button
          type={1}
          theme="dark"
          text={'Submit'}
          onClick={onClickSubmit}
        />
        <Button
          theme={props.theme}
          type='ghost-warn'
          onClick={onClickForgotPassword}
          text='Forgot Password'
          icon={<Icon icon='alert-circle-outline'/>}
          iconPosition='right'
          iconJustify='edge'
        />
      </form>
    </>
  )

  function onSubmitMFA(e) {
    e.preventDefault()
    setMFACode('')
    props.storePayload({isLoading: true})
    props.enterMFA(MFACode, props.mfaError)
  }

  const mfaTab = (
    <div className='mfa-div'>
      <form className='mfa-form' onSubmit={onSubmitMFA}>
        <p className='sea-green-text mfa-item'>
          Please check your two-factor authentication app
          and enter the one-time password in the field below:
        </p>
        <Input
          label='Security Code'
          key='mfa-code-field'
          name='mfaCode'
          id='mfaCode'
          className='custom-input  mfa-item'
          onChange={(e) => setMFACode(e.target.value)}
          value={MFACode}
          autoComplete="off"
          theme={'dark'}
          width='60%'
          onKeyDown={(e) => {
            if (e.key === 'Enter' && MFACode.length === 6) {
              onSubmitMFA(e)
            }
          }}
        />
        <footer className='mfa-item'>
          <Button
            className='custom-btn'
            theme={'dark'}
            text={'Enter Code'}
            onClick={onSubmitMFA}
          >
            Enter Code
          </Button>
          <Button
            type={10}
            onClick={() => {
              props.storePayload({onMFATab: false})
              setMFACode('')
            }}
            className='custom-btn cancel-btn'
            theme={'dark'}
            text={'Cancel'}
          >
            Cancel
          </Button>
        </footer>
      </form>
    </div>
  )

  return (
    <Fragment>
      {props.userMessage &&
        <Notification
          type={props.notificationType || 1}
          image={props.notificationIcon === 'check' ? Check : Warning}
          content={props.userMessage}
          meta={Helpers.timeStampToTime(Date.now())}
          showMeta={true}
          isVisible={props.isNotificationVisible}
          onClick={onClickNotification}
          style={
            props.notificationType === 3 ?
            {zIndex: 100, width: '30em'} :
            {zIndex: 100}
          }
          theme={'dark'}
          className={'notification-class'}
        />
      }
      { props.isLoading &&
        <Loader
          color={'white'}
          style={{
            width: '20%',
            height: '20%',
            position: 'fixed',
            top: 'calc(50vh - 20% / 2)',
            left: 'calc(50vw - 20% / 2)',
            zIndex: '100',
          }}
          theme='dark'
        />
      }
      <div className="flex-full vkg">
        {props.isSignedIn && Object.keys(props.database).length &&
          <Graph signOutFunction={signOutClick}/>
        }
        {props.onMFATab ? mfaTab : !props.isLoading && !props.isSignedIn &&
          <div
            className={'custom-modal modal'}
          >
            <header><img src={LazarusLogo} alt=''></img></header>
            <div className='modal-forms'>
              {signInFormRender}
            </div>
          </div>
        }
      </div>
    </Fragment>
  )
}

const mapStateToProps = (state, ownProps) => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  userMessage: state.userReducer.userMessage,
  notificationType: state.userReducer.notificationType,
  notificationIcon: state.userReducer.notificationIcon,
  isNotificationVisible: state.userReducer.isNotificationVisible,
  isSignedIn: state.userReducer.isSignedIn,
  authForm: state.userReducer.authForm,
  signInForm: state.userReducer.signInForm,
  defaultAuthForm: state.userReducer.defaultAuthForm,
  defaultSignInForm: state.userReducer.defaultSignInForm,
  isLoading: state.userReducer.isLoading,
  onMFATab: state.userReducer.onMFATab, // MFA related
  mfaError: state.userReducer.mfaError, // MFA related
  userId: state.userReducer.userId,
  theme: state.userReducer.theme,
})

export default connect(
    mapStateToProps,
    {createFirebaseApp, storePayload, signIn, onSignInSuccess, signOut, getCustomDomain, enterMFA},
)(VectorKnowledgeGraph)

