/* eslint-disable max-len */
import types from '../types'
import Helpers from '../Helpers'

export const toggleVisualization = (visKey, newVal, visType) => (dispatch, getState) => {
  const metadataValues = getState().userReducer.metadataValues
  const visualizations = getState().userReducer.visualizations
  const newVis = JSON.parse(JSON.stringify(visualizations))
  newVis[visKey]['isActive'] = newVal
  newVis[visKey]['type'] = visType
  if (newVal) { // setting to active
    // reset other vis. of same type to false
    const activeSetTypeInit = Helpers.getInputType(metadataValues[visKey][0])
    const activeSetType = activeSetTypeInit === 'date'? 'number': activeSetTypeInit
    const metaKeys = Object.keys(metadataValues)
    for (let keyIndex = 0; keyIndex < metaKeys.length; keyIndex++) {
      const keyTypeInit = Helpers.getInputType(metadataValues[metaKeys[keyIndex]][0])
      const keyType = keyTypeInit === 'date'? 'number': keyTypeInit
      if (visKey !== metaKeys[keyIndex] && keyType === activeSetType) {
        // same type
        newVis[metaKeys[keyIndex]]['isActive'] = false
      }
    }
  }
  dispatch({
    type: types.STORE_PAYLOAD,
    payload: {
      visualizations: newVis,
    },
  })
}
