import types from '../types'

const initialState = {
  /* Default values here */
  isLoading: true,
  modalMode: null,
  // eslint-disable-next-line max-len
  graphList: [],
  selectedGraphId: null,
  defaultAuthForm: {
    rikaiKey: '',
    orgId: '',
    authKey: '',
    licenseKey: '',
  },
  authForm: {
    rikaiKey: '',
    orgId: '',
    authKey: '',
    licenseKey: '',
  },
  signInForm: {
    username: '',
    password: '',
  },
  vkgDomain: null,
  unselectedModalNodes: [],
  theme: 'dark',
  isFilterOn: false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case types.STORE_PAYLOAD:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
